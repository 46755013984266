import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaStar } from "react-icons/fa";
import { Checkbox } from "../Forms";
import st from "../../../assets/stylesheet/style.module.scss";
import { Slider } from "@mui/material";
import { Form } from "react-bootstrap";
import { IoSearchSharp } from "react-icons/io5";

function valuetext(value: number) {
  return `${value}°C`;
}

const minDistance = 10;
export default function FilterSidebar() {
  const [value1, setValue1] = React.useState<number[]>([20, 80]);

  const handleChange1 = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const [value2, setValue2] = React.useState<number[]>([20, 80]);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const [selectedCities, setSelectedCities] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const cities = [
    "San Francisco",
    "New York",
    "Denver",
    "Los Angeles",
    "Boston",
  ];

  const handleCheckboxChange = (city: any) => {
    if (selectedCities.includes(city)) {
      setSelectedCities(
        selectedCities.filter((selectedCity) => selectedCity !== city)
      );
    } else {
      setSelectedCities([...selectedCities, city]);
    }
  };

  const filterCities = () => {
    // Filter cities based on the search input
    const filteredCities = cities.filter((city) =>
      city.toLowerCase().includes(searchInput.toLowerCase())
    );
    // If there are selected cities, filter the result further
    // if (selectedCities.length > 0) {
    //   return filteredCities.filter((city) => selectedCities.includes(city));
    // }
    return filteredCities;
  };

  return (
    <>
      <div className={`${cx.filterSidebar}`}>
        <div className={`${cx.cardBox}`}>
          <h5 className={`${cx.filterTitle}`}>Price Filter($)</h5>
          <div className={`${cx.rangeFilter}`}>
            <Slider
              getAriaLabel={() => "Minimum distance"}
              value={value1}
              onChange={handleChange1}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
              disableSwap
            />
          </div>
        </div>

        <div className={`${cx.cardBox}`}>
          <h5 className={`${cx.filterTitle}`}>All Cities</h5>
          <div>
            <Form className="d-flex search_relatedBtn mb-3">
              <Form.Control
                type="search"
                placeholder="Search Cities..."
                aria-label="Search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <button className="btn">
                <IoSearchSharp />
              </button>
            </Form>
          </div>
          <ul className={`${cx.listMenu}`}>
            {filterCities().map((city) => (
              <li key={city}>
                <label className={`${st.checkbox}`}>
                  {/* <input
                    type="checkbox"
                    checked={selectedCities.includes(city)}
                    onChange={() => handleCheckboxChange(city)}
                  /> */}
                  <Checkbox />
                  <div className={`${cx.name}`}>{city}</div>
                </label>
              </li>
            ))}
          </ul>
        </div>

        <div className={`${cx.cardBox}`}>
          <h5 className={`${cx.filterTitle}`}>All Muncipalities</h5>

          <ul className={`${cx.listMenu}`}>
            <li>
              <label className={`${st.checkbox}`}>
                <Checkbox /> <div className={`${cx.name}`}>Muncipalities 1</div>
              </label>
            </li>
            <li>
              <label className={`${st.checkbox}`}>
                <Checkbox /> <div className={`${cx.name}`}>Muncipalities 2</div>
              </label>
            </li>
            <li>
              <label className={`${st.checkbox}`}>
                <Checkbox /> <div className={`${cx.name}`}>Muncipalities 3</div>
              </label>
            </li>
            <li>
              <label className={`${st.checkbox}`}>
                <Checkbox /> <div className={`${cx.name}`}>Muncipalities 4</div>
              </label>
            </li>
            <li>
              <label className={`${st.checkbox}`}>
                <Checkbox /> <div className={`${cx.name}`}>Muncipalities 5</div>
              </label>
            </li>
          </ul>
        </div>

        <div className={`${cx.cardBox}`}>
          <h5 className={`${cx.filterTitle}`}>Rating</h5>
          <ul className={`${cx.rating}`}>
            <li className={`${cx.active}`}>
              <label className={`${st.checkbox}`}>
                <Checkbox />
                <div className={`${cx.stars}`}>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
              </label>
            </li>
            <li className={`${cx.active}`}>
              <label className={`${st.checkbox}`}>
                <Checkbox />
                <div className={`${cx.stars}`}>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
              </label>
            </li>
            <li className={`${cx.active}`}>
              <label className={`${st.checkbox}`}>
                <Checkbox />
                <div className={`${cx.stars}`}>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
              </label>
            </li>
            <li className={`${cx.active}`}>
              <label className={`${st.checkbox}`}>
                <Checkbox />
                <div className={`${cx.stars}`}>
                  <FaStar />
                  <FaStar />
                </div>
              </label>
            </li>
            <li className={`${cx.active}`}>
              <label className={`${st.checkbox}`}>
                <Checkbox />
                <div className={`${cx.stars}`}>
                  <FaStar />
                </div>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
