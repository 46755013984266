import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { MdFavoriteBorder } from "react-icons/md";
import { HiBuildingOffice } from "react-icons/hi2";
import { JobItem } from "../../../constants/Interface";
import { FaHeart } from "react-icons/fa";

const JobDetails = () => {

  const [favorites, setFavorite] = useState(false);
  
  const params = useParams();
  console.log(params, "Job Details: " + params.id);
  const [JobDetails, setJobDetails] = useState<JobItem>({
    id: 0,
    job_title: "",
    company_name: "",
    package: "",
    subject_area: { name: "", id: 0 },
    industry: { name: "", id: 0 },
    level_of_education: { name: "", id: 0 },
    region: { name: "", id: 0 },
    contract_type: { name: "", id: 0 },
    description: "",
    create_at: "",
    update_at: "",
  });

  async function getJobDetails() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/jobs/${params.id}`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      console.log(data, "Job Details 000");
      setJobDetails(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  useEffect(() => {
    getJobDetails();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={`${st.header_height}`}></div>

      <section className={`${cx.jobDetail_section} ${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col lg={11} className="m-auto">
              <div className={`${cx.heading_title}`}>
                <div className={`${cx.left_box}`}>
                  <h2>{JobDetails.job_title}</h2>
                  <h4>{JobDetails.company_name}</h4>
                  <div className={`${cx.locationPin}`}>
                    <IoLocationOutline /> {JobDetails.region.name!}
                  </div>
                </div>

                <div className={`${cx.right_box}`}>
                  {/* <div className={`${cx.chat}`}>
                    <NavLink to={"#"}>Chat</NavLink>
                  </div> */}
                  {/* <div className={`${cx.favorite}`}>
                    <NavLink to={"#"}>
                      <MdFavoriteBorder />
                    </NavLink>
                  </div> */}
                  <div className={`${cx.heart} `}>
                    <button
                      className={`${favorites ? cx.active : ""}`}
                      onClick={() => {
                        setFavorite(true);
                      }}
                    >
                      <FaHeart />
                    </button>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={11} className="m-auto">
              <Row>
                <Col lg={8}>
                  <div className={`${cx.about_job}`}>
                    <div className={`${cx.job_detailsBox}`}>
                      <h2>About the job</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: JobDetails.description!,
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className={`${cx.about_salary}`}>
                    <div className={`${cx.avg_slary}`}>
                      <h2>{JobDetails.package}</h2>
                      <p>per annum</p>
                    </div>
                    <ul className={`${cx.type_employ}`}>
                      <li>
                        <div className={`${cx.type_listBox}`}>
                          <div className={`${cx.list_icon}`}>
                            <HiBuildingOffice />
                          </div>
                          <div className={`${cx.type_describe}`}>
                            <h4>{JobDetails.industry.name!}</h4>
                            <p>Industry</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className={`${cx.type_listBox}`}>
                          <div className={`${cx.list_icon}`}>
                            <HiBuildingOffice />
                          </div>
                          <div className={`${cx.type_describe}`}>
                            <h4>{JobDetails.level_of_education.name!}</h4>
                            <p>Education</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className={`${cx.type_listBox}`}>
                          <div className={`${cx.list_icon}`}>
                            <HiBuildingOffice />
                          </div>
                          <div className={`${cx.type_describe}`}>
                            <h4>{JobDetails.contract_type.name!}</h4>
                            <p>Contract</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className={`${cx.type_listBox}`}>
                          <div className={`${cx.list_icon}`}>
                            <HiBuildingOffice />
                          </div>
                          <div className={`${cx.type_describe}`}>
                            <h4>{JobDetails.subject_area.name!}</h4>
                            <p>Subject Area</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default JobDetails;
