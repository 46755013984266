import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Row } from "react-bootstrap";
import OtpInput from "react-otp-input";
import "react-toastify/dist/ReactToastify.css";
import { Container, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const CreateAccountOTP = (props: any) => {
  const [email, setEmail] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const navigate = useNavigate();
  const [errorEmailMessage, setErrorEmailMessage] = useState("");
  const [errorPhoneMessage, setErrorPhoneMessage] = useState("");

  const handleVerification = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/login_signup/otp_ver/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },

          body: JSON.stringify({ otp_phone: phoneOtp, otp_email: email }),
        }
      );

      if (response.ok) {
        setTimeout(() => {
          navigate("/signup");
        }, 1000);
      } else {
        const errorData = await response.json();
        setErrorEmailMessage(errorData?.error2);
        setErrorPhoneMessage(errorData?.error1);
      }
    } catch (error) {
      console.error(error, "Error verifying OTP:");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className={`${cx.create_register}`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={`${cx.main_heading}`}>
                <h1>Create An Account OTP</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col lg={5} md={6} sm={12} className={`m-auto`}>
              <div className={`${cx.onboardingBox}`}>
                <div className={`mb-4 ${cx.onboardingHeading}`}>
                  <h2>Verify</h2>
                  <p>We send 4 degit code to your Email</p>
                  <div className={`${cx.formStart}`}>
                    <div className={`${cx.otpField}`}>
                      <OtpInput
                        value={email}
                        onChange={setEmail}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    {errorEmailMessage && (
                      <p style={{ color: "red" }}>{errorEmailMessage}</p>
                    )}
                  </div>
                </div>
                <div className={`mb-4 ${cx.onboardingHeading}`}>
                  <p>We send 4 degit code to your Phone</p>
                  <div className={`${cx.formStart}`}>
                    <div className={`${cx.otpField}`}>
                      <OtpInput
                        value={phoneOtp}
                        onChange={setPhoneOtp}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    {errorPhoneMessage && (
                      <p style={{ color: "red" }}>{errorPhoneMessage}</p>
                    )}
                    <div className={`${cx.onboardingButton}`}>
                      <Button className={`btn`} onClick={handleVerification}>
                        Verify
                      </Button>

                      {/* <NavLink to={"/signup"}  className={`btn`}>Verify</NavLink> */}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CreateAccountOTP;
