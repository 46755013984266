import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  usaid,
  bagIcon,
  certificateIcon,
  communityIcon,
} from "../../../assets/images";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ProductCard from "../../../components/Website/ProductCard";
import { HiOutlineHome, HiBuildingOffice2 } from "react-icons/hi2";
import { TbIdBadge } from "react-icons/tb";
import { IoNewspaperOutline } from "react-icons/io5";
import { TfiCar } from "react-icons/tfi";
import { FaPhotoVideo } from "react-icons/fa";
import { IoMdArrowForward } from "react-icons/io";
import { FaArrowLeft } from "react-icons/fa6";

const Home = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options2 = {
    loop: false,
    items: 4,
    dots: false,
    nav: true,
    navText: [
      `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`,
      `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`,
    ],
    autoplay: false,
    responsive: {
      0: {
        items: 2,
      },
      480: {
        items: 2,
      },
      768: {
        items: 4,
      },
      992: {
        items: 7,
      },
    },
  };

  const options3 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 3,
      },
      992: {
        items: 5,
      },
    },
  };

  const [activeAdd, setActiveAdd] = useState(false);

  return (
    <>
      <div className={`${st.header_height}`}></div>

      <section className={`${cx.banner_main}`}>
        <Container fluid style={{ maxWidth: "1500px" }}>
          <Row>
            <Col lg={4} md={5} className="ms-auto">
              <Col md={10} className="ms-auto">
                {!activeAdd && (
                  <div className={`${cx.banner_boxes}`}>
                    <NavLink
                      to={"#"}
                      className={`${cx.banner_attach}`}
                      onClick={() => {
                        setActiveAdd(true);
                      }}
                    >
                      <div className={`${cx.banner_FlexArea}`}>
                        <div className={`${cx.left}`}>
                          <div className={`${cx.img_box}`}>
                            <img src={bagIcon} alt="bag" />
                          </div>
                          <div className={`${cx.box_content}`}>
                            <h4>Post An Ad</h4>
                            <p>
                              <span>0</span> Ads Posted
                            </p>
                          </div>
                        </div>
                        <div className={`${cx.right}`}>
                          <IoMdArrowForward />
                        </div>
                      </div>
                    </NavLink>
                    <NavLink to={"/job"} className={`${cx.banner_attach}`}>
                      <div className={`${cx.banner_FlexArea}`}>
                        <div className={`${cx.left}`}>
                          <div className={`${cx.img_box}`}>
                            <img src={certificateIcon} alt="bag" />
                          </div>
                          <div className={`${cx.box_content}`}>
                            <h4>Search Jobs</h4>
                            <p>
                              <span>382</span> open vacancies
                            </p>
                          </div>
                        </div>
                        <div className={`${cx.right}`}>
                          <IoMdArrowForward />
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      to={"/all-categories"}
                      className={`${cx.banner_attach}`}
                    >
                      <div className={`${cx.banner_FlexArea}`}>
                        <div className={`${cx.left}`}>
                          <div className={`${cx.img_box}`}>
                            <img src={communityIcon} alt="bag" />
                          </div>
                          <div className={`${cx.box_content}`}>
                            <h4>Immovable</h4>
                            <p>
                              <span>2M+</span> open vacancies
                            </p>
                          </div>
                        </div>
                        <div className={`${cx.right}`}>
                          <IoMdArrowForward />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                )}

                {activeAdd && (
                  <div
                    className={`${cx.banner_boxes} ${cx.another_banner_boxes}`}
                  >
                    <div className={`${cx.back_btn}`}>
                      <button
                        className="btn"
                        onClick={() => {
                          setActiveAdd(false);
                        }}
                      >
                        <FaArrowLeft />
                      </button>
                    </div>

                    <NavLink
                      to={"/add-product"}
                      className={`${cx.banner_attach}`}
                    >
                      <div className={`${cx.banner_FlexArea}`}>
                        <div className={`${cx.left}`}>
                          <div className={`${cx.img_box}`}>
                            <img src={bagIcon} alt="bag" />
                          </div>
                          <div className={`${cx.box_content}`}>
                            <h4>Product</h4>
                            <p>
                              <span>0</span> open vacancies
                            </p>
                          </div>
                        </div>
                        <div className={`${cx.right}`}>
                          <IoMdArrowForward />
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      to={"/post-a-job"}
                      className={`${cx.banner_attach}`}
                    >
                      <div className={`${cx.banner_FlexArea}`}>
                        <div className={`${cx.left}`}>
                          <div className={`${cx.img_box}`}>
                            <img src={certificateIcon} alt="bag" />
                          </div>
                          <div className={`${cx.box_content}`}>
                            <h4>Job</h4>
                            <p>
                              <span>382</span> open vacancies
                            </p>
                          </div>
                        </div>
                        <div className={`${cx.right}`}>
                          <IoMdArrowForward />
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      to={"/post-a-tender"}
                      className={`${cx.banner_attach}`}
                    >
                      <div className={`${cx.banner_FlexArea}`}>
                        <div className={`${cx.left}`}>
                          <div className={`${cx.img_box}`}>
                            <img src={communityIcon} alt="bag" />
                          </div>
                          <div className={`${cx.box_content}`}>
                            <h4>Tender</h4>
                            <p>
                              <span>2M+</span> open vacancies
                            </p>
                          </div>
                        </div>
                        <div className={`${cx.right}`}>
                          <IoMdArrowForward />
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      to={"/post-a-news"}
                      className={`${cx.banner_attach}`}
                    >
                      <div className={`${cx.banner_FlexArea}`}>
                        <div className={`${cx.left}`}>
                          <div className={`${cx.img_box}`}>
                            <img src={communityIcon} alt="bag" />
                          </div>
                          <div className={`${cx.box_content}`}>
                            <h4>News</h4>
                            <p>
                              <span>2M+</span> open vacancies
                            </p>
                          </div>
                        </div>
                        <div className={`${cx.right}`}>
                          <IoMdArrowForward />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                )}
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={`${cx.filter_topSlider} ${st.spb} pb-0`}>
        <Container>
          <div className={`${cx.slideMain_box}`}>
            <div className={`${cx.left}`}>
              <OwlCarousel className="owl-theme" {...options2}>
                <div className="item">
                  <NavLink to={"/tender"} className={`${cx.boxes}`}>
                    <TbIdBadge />
                    <p>Tenders</p>
                  </NavLink>
                </div>
                <div className="item">
                  <NavLink to={"/all-categories"} className={`${cx.boxes}`}>
                    <TfiCar />
                    <p>Automobile</p>
                  </NavLink>
                </div>
                <div className="item">
                  <NavLink to={"/all-categories"} className={`${cx.boxes}`}>
                    <FaPhotoVideo />
                    <p>Multimedia</p>
                  </NavLink>
                </div>
                <div className="item">
                  <NavLink to={"/all-categories"} className={`${cx.boxes}`}>
                    <HiOutlineHome />
                    <p>Immovable</p>
                  </NavLink>
                </div>
                <div className="item">
                  <NavLink to={"/job"} className={`${cx.boxes}`}>
                    <HiBuildingOffice2 />
                    <p>Jobs</p>
                  </NavLink>
                </div>
                <div className="item">
                  <NavLink to={"/news"} className={`${cx.boxes}`}>
                    <IoNewspaperOutline />
                    <p>News</p>
                  </NavLink>
                </div>
                <div className="item">
                  <NavLink to={"/all-categories"} className={`${cx.boxes}`}>
                    <HiOutlineHome />
                    <p>All Categories</p>
                  </NavLink>
                </div>
                {/* <div className='item'>
                  <NavLink to={"/home-1"} className={`${cx.boxes}`}>
                    <HiOutlineHome />
                    <p>See All</p>
                  </NavLink>
                </div> */}
              </OwlCarousel>
            </div>
          </div>
        </Container>
      </section>

      {/* <section className={`${cx.productCategory}`}>
        <Container>
          <Row>
            <Col md={7}>
              <div className={`${cx.category_locationSec}`}>
                <div className={`${cx.main_heading}`}>
                  <h1>Lorem ipsum doloraa sit amet consecte </h1>
                  <p>Lorem ipsum dolor sit amet consectetur. Condimentum sit
                    donec pel enim. Nunc diam sed volutpat non amet habitant elementum.
                    Amet nam senectus orci eu lacus. Aliquam po amet dolat amet dolat</p>
                </div>
                <div className={`${cx.selectDropdown}`}>
                  <div className={`${cx.selectValue}`}>
                    <FaLocationDot className={`${cx.icon}`} />
                    <select className="form-select">
                      <option value="Location">Your Location</option>
                    </select>
                  </div>
                  <div className={`${cx.selectValue}`}>
                    <BiCategory className={`${cx.icon}`} />
                    <select className="form-select">
                      <option value="Category">Product Category</option>
                    </select>
                  </div>
                  <div className={`${cx.selectValue}`}>
                    <IoPricetag className={`${cx.icon}`} />
                    <select className="form-select">
                      <option value="Range">Price Range</option>
                    </select>

                  </div>
                  <div className={`d-flex justify-content-end ${cx.selectValue}`}>
                    <button>Search</button>
                  </div>
                </div>
                <div className={`${cx.bannerImage}`}>
                  <img src={clothing} alt="clothing" />
                  <img src={carProduct} alt="car" />
                </div>
              </div>
            </Col>
            <Col md={5}>
              <div className={`${cx.bannerSideImg}`}>
                <img src={product} alt="banner" />
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      <section className={`${cx.rentList_Sides} ${st.spb}`}>
        <Container>
          <Row>
            <Col md={12} className="mb-2">
              {/* <div className={`${cx.see_allBtn}`}>
                <NavLink to={"/home-1"}>See All</NavLink>
              </div> */}
              <div className={`${cx.main_heading}`}>
                <h2>Popular Suggestion</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6} className="mb-4">
              <ProductCard />
            </Col>
            <Col lg={3} md={6} className="mb-4">
              <ProductCard />
            </Col>
            <Col lg={3} md={6} className="mb-4">
              <ProductCard />
            </Col>
            <Col lg={3} md={6} className="mb-4">
              <ProductCard />
            </Col>
            <Col lg={3} md={6} className="mb-4">
              <ProductCard />
            </Col>
            <Col lg={3} md={6} className="mb-4">
              <ProductCard />
            </Col>
            <Col lg={3} md={6} className="mb-4">
              <ProductCard />
            </Col>
            <Col lg={3} md={6} className="mb-4">
              <ProductCard />
            </Col>
            <Col lg={3} md={6} className="mb-4">
              <ProductCard />
            </Col>
            <Col lg={3} md={6} className="mb-4">
              <ProductCard />
            </Col>
            <Col lg={3} md={6} className="mb-4">
              <ProductCard />
            </Col>
            <Col lg={3} md={6} className="mb-4">
              <ProductCard />
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className={`${cx.access_adsSec} ${st.paddingBottom}`}>
        <Container>
          <Row>
            <Col md={7} className={`${cx.paddingRight}`}>
              <div className={`${cx.main_heading} mb-4`}>
                <h2>Quick Access</h2>
              </div>
              <OwlCarousel className='owl-theme' {...options}>
                <div className='item'>
                  <NavLink to={"#"} className={`${cx.quick_AccessBox}`}>
                    <img src={car} alt="car" />
                    <div className={`${cx.contentBox}`}>
                      <h4>Lorem ispum dolar  sit amrt dit domace amet</h4>
                      <p>2500 Adds Recomended</p>
                    </div>
                  </NavLink>
                  <NavLink to={"#"} className={`${cx.quick_AccessBox}`}>
                    <img src={car} alt="car" />
                    <div className={`${cx.contentBox}`}>
                      <h4>Lorem ispum dolar  sit amrt dit domace amet</h4>
                      <p>2500 Adds Recomended</p>
                    </div>
                  </NavLink>
                  <NavLink to={"#"} className={`${cx.quick_AccessBox}`}>
                    <img src={car} alt="car" />
                    <div className={`${cx.contentBox}`}>
                      <h4>Lorem ispum dolar  sit amrt dit domace amet</h4>
                      <p>2500 Adds Recomended</p>
                    </div>
                  </NavLink>
                  <NavLink to={"#"} className={`${cx.quick_AccessBox}`}>
                    <img src={car} alt="car" />
                    <div className={`${cx.contentBox}`}>
                      <h4>Lorem ispum dolar  sit amrt dit domace amet</h4>
                      <p>2500 Adds Recomended</p>
                    </div>
                  </NavLink>
                  <NavLink to={"#"} className={`${cx.quick_AccessBox}`}>
                    <img src={car} alt="car" />
                    <div className={`${cx.contentBox}`}>
                      <h4>Lorem ispum dolar  sit amrt dit domace amet</h4>
                      <p>2500 Adds Recomended</p>
                    </div>
                  </NavLink>
                </div>
                <div className='item'>
                  <NavLink to={"#"} className={`${cx.quick_AccessBox}`}>
                    <img src={car} alt="car" />
                    <div className={`${cx.contentBox}`}>
                      <h4>Lorem ispum dolar  sit amrt dit domace amet</h4>
                      <p>2500 Adds Recomended</p>
                    </div>
                  </NavLink>
                  <NavLink to={"#"} className={`${cx.quick_AccessBox}`}>
                    <img src={car} alt="car" />
                    <div className={`${cx.contentBox}`}>
                      <h4>Lorem ispum dolar  sit amrt dit domace amet</h4>
                      <p>2500 Adds Recomended</p>
                    </div>
                  </NavLink>
                  <NavLink to={"#"} className={`${cx.quick_AccessBox}`}>
                    <img src={car} alt="car" />
                    <div className={`${cx.contentBox}`}>
                      <h4>Lorem ispum dolar  sit amrt dit domace amet</h4>
                      <p>2500 Adds Recomended</p>
                    </div>
                  </NavLink>
                  <NavLink to={"#"} className={`${cx.quick_AccessBox}`}>
                    <img src={car} alt="car" />
                    <div className={`${cx.contentBox}`}>
                      <h4>Lorem ispum dolar  sit amrt dit domace amet</h4>
                      <p>2500 Adds Recomended</p>
                    </div>
                  </NavLink>
                  <NavLink to={"#"} className={`${cx.quick_AccessBox}`}>
                    <img src={car} alt="car" />
                    <div className={`${cx.contentBox}`}>
                      <h4>Lorem ispum dolar  sit amrt dit domace amet</h4>
                      <p>2500 Adds Recomended</p>
                    </div>
                  </NavLink>
                </div>

              </OwlCarousel>
            </Col>

            <Col md={5}>
              <div className={`${cx.main_heading} mb-4`}>
                <h2>Suggested Adds</h2>
              </div>

              <OwlCarousel className='owl-theme' {...options1}>
                <div className='item'>
                  <NavLink to={"#"} className={`${cx.ads_imgBox}`} >
                    <img src={adsBanner} alt="" />
                  </NavLink>
                  <NavLink to={"#"} className={`${cx.ads_imgBox}`} >
                    <img src={adsBanner} alt="" />
                  </NavLink>
                  <NavLink to={"#"} className={`${cx.ads_imgBox}`} >
                    <img src={adsBanner} alt="" />
                  </NavLink>
                  <NavLink to={"#"} className={`${cx.ads_imgBox}`} >
                    <img src={adsBanner} alt="" />
                  </NavLink>
                </div>
                <div className='item'>
                  <NavLink to={"#"} className={`${cx.ads_imgBox}`} >
                    <img src={adsBanner} alt="" />
                  </NavLink>
                  <NavLink to={"#"} className={`${cx.ads_imgBox}`} >
                    <img src={adsBanner} alt="" />
                  </NavLink>
                  <NavLink to={"#"} className={`${cx.ads_imgBox}`} >
                    <img src={adsBanner} alt="" />
                  </NavLink>
                  <NavLink to={"#"} className={`${cx.ads_imgBox}`} >
                    <img src={adsBanner} alt="" />
                  </NavLink>
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section> */}

      <section
        className={`${cx.suggestAddsSec_slider} my-4 py-4 mb-5 border-top border-bottom`}
      >
        <Container>
          <Col md={12}>
            <div className="text-center mb-5">
              <h2>Suggested Adds</h2>
            </div>
          </Col>

          <Col md={12}>
            <OwlCarousel className="owl-theme" {...options3}>
              <div className="item">
                <div className="me-3 ms-3">
                  <img src={usaid} alt="aid" />
                </div>
              </div>
              <div className="item">
                <div className="me-3 ms-3">
                  <img src={usaid} alt="aid" />
                </div>
              </div>
              <div className="item">
                <div className="me-3 ms-3">
                  <img src={usaid} alt="aid" />
                </div>
              </div>
              <div className="item">
                <div className="me-3 ms-3">
                  <img src={usaid} alt="aid" />
                </div>
              </div>
              <div className="item">
                <div className="me-3 ms-3">
                  <img src={usaid} alt="aid" />
                </div>
              </div>
              <div className="item">
                <div className="me-3 ms-3">
                  <img src={usaid} alt="aid" />
                </div>
              </div>
              <div className="item">
                <div className="me-3 ms-3">
                  <img src={usaid} alt="aid" />
                </div>
              </div>
            </OwlCarousel>
          </Col>
        </Container>
      </section>
    </>
  );
};

export default Home;
