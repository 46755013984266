import React, { useEffect } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import { profile } from "../../../assets/images";
import { MdEdit } from "react-icons/md";
import { PiStar, PiStarFill } from "react-icons/pi";
import { useForm } from "react-hook-form";

const MyProfile = () => {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  async function getProfile() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${
          JSON.parse(localStorage.getItem("userData")!)?.id
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      reset({
        firstName: data.firstName,
        lastName: data.lastName,
        uploadImages: data.uploadImages,
        city: data.city,
        state: data.state,
        email: data.email,
        phoneNo: data.phoneNo,
      });
      console.log(data, "000000000000000000000");
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    phoneNo,
    uploadImages,
    city,
    state,
  }: any) => {
    try {
      const formData = new FormData();
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("email", email);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("phoneNo", phoneNo);
      formData.append("uploadImages", uploadImages[0]);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${
          JSON.parse(localStorage.getItem("userData")!)?.id
        }/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      reset({
        firstName: data.firstName,
        lastName: data.lastName,
        uploadImages: data.uploadImages,
        city: data.city,
        state: data.state,
        email: data.email,
        phoneNo: data.phoneNo,
      });
      console.log(data, "000000000000000000000");
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  useEffect(() => {
    getProfile();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={`${st.header_height}`}></div>
      <section className={`${cx.edit_prodfileSec} ${cx.pageSpace}`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <Col md={12} sm={12}>
              <div className={`${cx.profile_main}`}>
                <h2>My Profile</h2>
                <div className={`${cx.userImage}`}>
                  <img
                    src={getValues("uploadImages") ?? profile}
                    alt="user-review"
                  />
                  <div className={`${cx.editIcon}`}>
                    <div className={`${cx.edit}`}>
                      <MdEdit />
                      <input
                        type="file"
                        {...register("uploadImages", { required: true })}
                      />
                      {errors.uploadImages && (
                        <span>This field is required</span>
                      )}
                    </div>
                  </div>
                </div>

                <Row>
                  <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="first name"
                      {...register("firstName", { required: true })}
                    />
                    {errors.firstName && <span>This field is required</span>}
                  </Col>
                  <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="last name"
                      {...register("lastName", { required: true })}
                    />
                    {errors.lastName && <span>This field is required</span>}
                  </Col>
                  {/* <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>DOB</label>
                    <input
                      type="date"
                      className="form-control"
                      {...register("date", { required: true })}
                    />
                    {errors.date && <span>This field is required</span>}
                  </Col> */}
                  <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      placeholder="999-999-9999"
                      {...register("phoneNo", { required: true })}
                    />
                    {errors.number && <span>This field is required</span>}
                  </Col>
                  <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled
                      type="email"
                      className="form-control"
                      placeholder="user@example.com"
                      {...register("email", { required: true })}
                    />
                    {errors.email && <span>This field is required</span>}
                  </Col>
                  {/* <Col md={12} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      Street Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Street address"
                      {...register("street", { required: true })}
                    />
                    {errors.street && <span>This field is required</span>}
                  </Col> */}
                  {/* <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      Apartment Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Apartment number (optional)"
                      {...register("apartment", { required: true })}
                    />
                    {errors.apartment && <span>This field is required</span>}
                  </Col> */}
                  <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      City <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      {...register("city", { required: true })}
                    />
                    {errors.city && <span>This field is required</span>}
                  </Col>
                  <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      State <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="State"
                      {...register("state", { required: true })}
                    />
                    {errors.state && <span>This field is required</span>}
                  </Col>
                  {/* <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}>
                      Pin Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pin code"
                      {...register("pincode", { required: true })}
                    />
                    {errors.pincode && <span>This field is required</span>}
                  </Col> */}
                  <Col md={6} className={`m-auto ${st.btnsGroup} text-center`}>
                    <button className={`btn w-100 ${st.fillBtn}`}>
                      Submit
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Container>
        </form>
      </section>

      <div className={`${cx.reviewSection}`}>
        <Container>
          <Row>
            <Col lg={12}>
              <div className={`${cx.contentReview}`}>
                <h2>Latest Review</h2>
              </div>
            </Col>
            <Col lg={6}>
              <div className={`${cx.cardBox}`}>
                <div className={`text-center`}>
                  <img src={profile} alt="user-review" />
                  <ul className={`d-flex align-items-center small`}>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStar />
                    </li>
                  </ul>
                </div>
                <div className={`${cx.userDetails}`}>
                  <h3>Jonathan E. Applin</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Blanditiis sint unde debitis, omnis, ea odit ipsa
                    voluptatem, autem quia incidunt fugit?
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className={`${cx.cardBox}`}>
                <div className={`text-center`}>
                  <img src={profile} alt="user-review" />
                  <ul className={`d-flex align-items-center small`}>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStar />
                    </li>
                  </ul>
                </div>
                <div className={`${cx.userDetails}`}>
                  <h3>Jonathan E. Applin</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Blanditiis sint unde debitis, omnis, ea odit ipsa
                    voluptatem, autem quia incidunt fugit?
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className={`${cx.cardBox}`}>
                <div className={`text-center`}>
                  <img src={profile} alt="user-review" />
                  <ul className={`d-flex align-items-center small`}>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStar />
                    </li>
                  </ul>
                </div>
                <div className={`${cx.userDetails}`}>
                  <h3>Jonathan E. Applin</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Blanditiis sint unde debitis, omnis, ea odit ipsa
                    voluptatem, autem quia incidunt fugit?
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className={`${cx.cardBox}`}>
                <div className={`text-center`}>
                  <img src={profile} alt="user-review" />
                  <ul className={`d-flex align-items-center small`}>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStar />
                    </li>
                  </ul>
                </div>
                <div className={`${cx.userDetails}`}>
                  <h3>Jonathan E. Applin</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Blanditiis sint unde debitis, omnis, ea odit ipsa
                    voluptatem, autem quia incidunt fugit?
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className={`${cx.cardBox}`}>
                <div className={`text-center`}>
                  <img src={profile} alt="user-review" />
                  <ul className={`d-flex align-items-center small`}>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStar />
                    </li>
                  </ul>
                </div>
                <div className={`${cx.userDetails}`}>
                  <h3>Jonathan E. Applin</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Blanditiis sint unde debitis, omnis, ea odit ipsa
                    voluptatem, autem quia incidunt fugit?
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className={`${cx.cardBox}`}>
                <div className={`text-center`}>
                  <img src={profile} alt="user-review" />
                  <ul className={`d-flex align-items-center small`}>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStarFill />
                    </li>
                    <li>
                      <PiStar />
                    </li>
                  </ul>
                </div>
                <div className={`${cx.userDetails}`}>
                  <h3>Jonathan E. Applin</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Blanditiis sint unde debitis, omnis, ea odit ipsa
                    voluptatem, autem quia incidunt fugit?
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MyProfile;
