import React from "react";
import st from "../../../assets/stylesheet/style.module.scss";

const Checkbox = ({ name }: { name?: string }) => {
  return (
    <>
      <input type="checkbox" value={name} />
      <span className={`${st.checkmark}`}></span>
    </>
  );
};

export default Checkbox;
