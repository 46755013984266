import React from "react";
import m from "./Modal.module.scss";
import { Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Logout = (props: any) => {
  let { show, handleLogoutClose } = props;

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    toast.success("Logged Out Successfully");
    handleLogoutClose();
    setTimeout(() => {
      navigate("/");
    }, 3000);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleLogoutClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Header>
          <Modal.Title>Logout</Modal.Title>
          <button className={`${m.closeIcon}`} onClick={handleLogoutClose}>
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h3 className={`text-center`}>Are you sure you want to logout?</h3>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={`btn ${m.cancelBtn}`}
            onClick={handleLogoutClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`btn ${m.actionBtn}`}
            onClick={handleLogout}
          >
            Logout
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Logout;
