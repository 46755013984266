import React from "react";
import m from "./Modal.module.scss";
import { Col, Modal,Row } from "react-bootstrap";
import { MdClose } from 'react-icons/md';

const SellerDetails = (props: any) => {
  let { show, handleSellerDetailsClose } = props;
  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleSellerDetailsClose}
        aria-labelledby="example-modal-sizes-title-sm" className={m.modalCts}
      >
        <Modal.Header>
            <Modal.Title><b>Seller Details</b></Modal.Title>
            <button className={`${m.closeIcon}`} onClick={handleSellerDetailsClose}><MdClose /></button>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12} className="mb-3">
                <div className={`${m.filled_Input}`}>
                  <div className={`${m.label_tag}`}>E-mail:</div>
                  <div className={`${m.input_tag}`}>example@gmail.com</div>
                </div>
              </Col>
              <Col md={12}>
                <div className={`${m.filled_Input}`}>
                  <div className={`${m.label_tag}`}>Phone No:</div>
                  <div className={`${m.input_tag}`}>9876543211</div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          
      </Modal>
    </>
  );
};

export default SellerDetails;