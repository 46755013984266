import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Button, Row } from "react-bootstrap";
import { Container, Col, Form } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { google, facebook, microsoft, yahooIcon } from "../../../assets/images";

const Login = () => {
  const [loginData, setLoginData] = useState("");
  const navigate = useNavigate();

  const onSubmit = async () => {
    try {
      let isEmail = /\S+@\S+\.\S+/.test(loginData);
      const formdata = new FormData();
      if (isEmail) {
        formdata.append("email", loginData);
      } else {
        formdata.append("phoneNo", "+91" + loginData);
      }

      const requestOptions = {
        method: "POST",
        body: formdata,
      };

      fetch(`${process.env.REACT_APP_BASEURL}/login/`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          navigate("/otp", {
            state: { [isEmail ? "email" : "phone"]: loginData },
          });
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className={`${cx.create_register}`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={`${cx.main_heading}`}>
                <h1>Login</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col lg={6} md={9} sm={12} className={`m-auto`}>
              <div className={`${cx.formBox}`}>
                <h3>Login</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Condimentum sit donec
                  pel enim
                </p>
                <NavLink className="btn mt-3" to={"#"}>
                  <img src={google} alt="google" /> Sign in with Google
                </NavLink>

                <NavLink className="btn mt-3" to={"#"}>
                  <img src={facebook} alt="facebook" /> Sign in with Facebook
                </NavLink>
                <NavLink className="btn mt-3" to={"#"}>
                  <img src={microsoft} alt="microsoft" /> Sign in with Microsoft
                </NavLink>
                <NavLink className="btn mt-3" to={"#"}>
                  <img src={yahooIcon} alt="yahoo" /> Sign in with Yahoo
                </NavLink>

                <div className={`${cx.line_box}`}>
                  <div className={`${cx.line}`}></div>
                  <div className={`${cx.line_content}`}>
                    or sign in with Phone No.
                  </div>
                </div>

                <Form>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>
                      Phone No. / E-mail<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your email or phone number"
                      value={loginData}
                      onChange={(e) => setLoginData(e.target.value)}
                    />
                  </Form.Group>

                  {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Password<span className="text-danger">*</span></Form.Label>
                    <Form.Control type="password" placeholder="Your Password" />
                  </Form.Group>

                  <div className={`${cx.forget_checks} mb-4`}>
                    <div className={`${cx.left}`}>
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        label="Remember Me"
                      />
                    </div>
                    <div className={`${cx.right}`}>
                      <NavLink to={"#"}>Forgot Password?</NavLink>
                    </div>
                  </div> */}

                  <div className={`${cx.submitBtn} mb-3`}>
                    <Button type="button" className="btn" onClick={onSubmit}>
                      Login
                    </Button>
                    {/* <NavLink className="btn" to={"/otp"} onClick={}>Login</NavLink> */}
                  </div>

                  <div className={`${cx.not_Register}`}>
                    <span>Not registered?</span>&nbsp;
                    <NavLink to={"/CreateAccount"}>Create an Account</NavLink>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Login;
