import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Pagination, Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import TenderTable from "../../../components/Website/TenderTable/TenderTable";

const Tender = () => {
  const [tenderList, setTenderList] = useState([]);
  const { register, handleSubmit } = useForm();

  const onSubmit = ({ title, body }: any) => {
    // Handle the form submission
    getTenderList(title ?? "", body ?? "");
  };

  async function getTenderList(title = "", body = "") {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/tender/tender-details/?title__contains=${title}&body__contains=${body}`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setTenderList(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  useEffect(() => {
    getTenderList();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className={`${cx.create_register}`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={`${cx.main_heading}`}>
                <h1>Calls for Tender</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.searchBox}`}>
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg="12" md={12} sm="12">
                <div className={`${cx.sarcheHeading}`}>
                  <h3>Search</h3>
                </div>
              </Col>
              <Col lg="5" md={5} sm="12">
                <div className={`${cx.sarcheBar}`}>
                  <input
                    type="search"
                    className={`form-control`}
                    placeholder="Object or reference"
                    {...register("title")}
                  />
                </div>
              </Col>
              <Col lg="5" md={5} sm="12">
                <div className={`${cx.sarcheBar}`}>
                  <input
                    type="search"
                    className={`form-control`}
                    placeholder="Body"
                    {...register("body")}
                  />
                </div>
              </Col>
              <Col lg="2" md={2} sm="12">
                <div className={`${cx.sarcheBar}`}>
                  <button className={`btn`} type="submit">
                    Search
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </section>

      <TenderTable
        cx={cx}
        tenderList={tenderList}
        getTenderList={getTenderList}
      />

      <section className={`pt-1 mt-4 mb-5`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={`${cx.paginationBox}`}>
                <Pagination>
                  <Pagination.First />
                  <Pagination.Prev />
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Item active>{2}</Pagination.Item>
                  <Pagination.Item disabled>{3}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{20}</Pagination.Item>
                  <Pagination.Next />
                  <Pagination.Last />
                </Pagination>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Tender;
