import React from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { IoCall, IoLocationSharp } from "react-icons/io5";
import { IoMdMailUnread } from "react-icons/io";

const ContactUs = (props: any) => {
  return (
    <>
      <div className={`${st.header_height}`}></div>

      <section className={`${cx.topBanner}`}>
        <Container>
          <Row>
            <Col md="8" lg="8" sm="12" className={`m-auto`}>
              <div className={`text-center ${cx.content}`}>
                <h1>Lets Talk</h1>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Necessitatibus, cum voluptatem? Ducimus aperiam odit aliquid
                  dolore itaque provident fuga. Laudantium blanditiis doloremque
                  cupiditate deserunt. Possimus veniam repudiandae quis mollitia
                  consectetur?
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.contactPage}`}>
        <Container>
          <div className={`${cx.contactBox}`}>
            <Row>
              <Col md="5" lg="4" sm="12">
                <div className={`${cx.getInTouch}`}>
                  <h2>Get In Touch</h2>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Minus minima eum quidem optio facere.
                  </p>
                  <ul>
                    <li>
                      <IoCall />
                      <NavLink to="#">+91 7891023456</NavLink>
                    </li>
                    <li>
                      <IoMdMailUnread />
                      <NavLink to="#">seller@gmail.com</NavLink>
                    </li>
                    <li>
                      <IoLocationSharp />
                      <NavLink to="#">Mansarovar Jaipur, Rajasthan</NavLink>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="7" lg="8" sm="12">
                <div className={`${cx.formStart}`}>
                  <Row className={`align-items-center`}>
                    <Col lg="6" md="12">
                      <div className={`mb-4`}>
                        <input
                          className={`form-control`}
                          type="text"
                          placeholder="Full Name"
                        />
                      </div>
                    </Col>
                    <Col lg="6" md="12">
                      <div className={`mb-4`}>
                        <input
                          className={`form-control`}
                          type="email"
                          placeholder="Email"
                        />
                      </div>
                    </Col>
                    <Col lg="6" md="12">
                      <div className={`mb-4`}>
                        <input
                          className={`form-control`}
                          type="phone"
                          placeholder="Phone Number"
                        />
                      </div>
                    </Col>
                    <Col lg="6" md="12">
                      <div className={`mb-4`}>
                        <input
                          className={`form-control`}
                          type="text"
                          placeholder="Subject"
                        />
                      </div>
                    </Col>
                    <Col lg="12" md="12">
                      <div className={`mb-4`}>
                        <textarea
                          className={`form-control ${cx.textArea}`}
                          placeholder="Comment"
                        ></textarea>
                      </div>
                    </Col>
                  </Row>

                  <div className={`${cx.onboardingButton}`}>
                    <NavLink to="#" className={`btn`}>
                      Send your Message
                    </NavLink>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ContactUs;
