import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";
import Footer from "../Footer";
import HeaderSub from "../Header1";

export default function Navigation() {
  const location = useLocation();
  console.log(location, "location");
  return (
    <>
      <div className={`${st.font} ${cx.websiteWrapper}`}>
        {/* {location.pathname === '/' ?<HeaderSub />:<HeaderSub />} */}
        <HeaderSub />
        <Outlet />
        <Footer />
      </div>
    </>
  );
}
