import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Collapse, Row } from "react-bootstrap";
import st from "../../../assets/stylesheet/style.module.scss";
import { Container, Col, Button } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { building, profile } from "../../../assets/images";
import {
  FaFacebookF,
  FaFacebookMessenger,
  FaHeart,
  FaLinkedinIn,
  FaRegComment,
} from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { FaArrowLeftLong, FaArrowRightLong, FaTwitter } from "react-icons/fa6";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import { IoMdMail } from "react-icons/io";
import { NewsItem } from "../../../constants/Interface";

const NewsDetails = () => {
  const params = useParams();
  console.log(params, "News Details: " + params.id);
  const [newsDetails, setNewsDetails] = useState<NewsItem>({
    id: 1,
    title: "",
    news_article: {
      id: 8,
      name: "",
    },
    description: "",
    news_image: "",
    create_time: "",
    update_time: "",
  });

  async function getNewsDetails() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/news/news/${params.id}`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      console.log(data, "News Details 000");
      setNewsDetails(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  useEffect(() => {
    getNewsDetails();
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = useState(false);

  return (
    <>
      <div className={`${st.header_height}`}></div>

      <section className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={`${cx.title_TopHeading}`}>
                <h4>
                  <span>Society:</span> Popular Enthusiasm to Vote Despite
                </h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={8}>
              <div className={`${cx.post_list_left}`}>
                <div className={`${cx.post_wrapper}`}>
                  <img
                    src={newsDetails.news_image!}
                    alt="news"
                    className={`${cx.Top_img}`}
                  />
                  <div className={`${cx.content_details}`}>
                    <div className={`${cx.tag_line}`}>
                      {newsDetails.news_article?.name}
                    </div>

                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <ul className={`${cx.share_icons}`}>
                        <li>
                          <NavLink to={"#"}>
                            <FaFacebookF />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <FaLinkedinIn />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <FaFacebookMessenger />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <FaTwitter />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <IoMdMail />
                          </NavLink>
                        </li>
                      </ul>

                      <div className={`${cx.addFavorite}`}>
                        <button>
                          <FaHeart /> Add To Favorites
                        </button>
                      </div>
                    </div>

                    <h3>{newsDetails.title}</h3>
                    <ul className={`${cx.date_comments}`}>
                      <li>
                        <div className={`${cx.flex_box}`}>
                          <MdDateRange />
                          {new Date(newsDetails.create_time!).toDateString()}
                        </div>
                      </li>
                      <li>
                        <div className={`${cx.flex_box}`}>
                          <FaRegComment />
                          06 Comments
                        </div>
                      </li>
                    </ul>

                    <div className={`${cx.para_box}`}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: newsDetails.description!,
                        }}
                      />
                    </div>
                  </div>

                  <div className={`${cx.content_details}`}>
                    <div className={`${cx.navigation_wrapBtn}`}>
                      <NavLink to={"#"} className={`${cx.prev_next}`}>
                        <FaArrowLeftLong /> Prev Post
                      </NavLink>
                      <NavLink to={"#"} className={`${cx.prev_next}`}>
                        Next Post <FaArrowRightLong />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${cx.comments_mainBox}`}>
                <div className={`${cx.heading_top}`}>Comments</div>

                <div className={`${cx.commentsFlex}`}>
                  <div className={`${cx.left_Imgbox}`}>
                    <img src={profile} alt="profile" />
                  </div>
                  <div>
                    <div className={`${cx.right_detailBox}`}>
                      <div className={`${cx.name_date}`}>
                        <h6>SHAKA@2ZKD172</h6>
                        <span>2 weeks ago</span>
                      </div>
                      <p className={`${cx.content_text}`}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Et reiciendis voluptatum velit? Accusantium,
                        asperiores ex dolore nostrum adipisci quia ad blanditiis
                        nemo officiis aspernatur temporibus accusamus explicabo
                        quod, voluptates deserunt.
                      </p>
                      <div className={`${cx.likeDislike_reply}`}>
                        <div className={`${cx.like_btn}`}>
                          <button>
                            <AiOutlineLike />
                          </button>
                          <small>22</small>
                        </div>
                        <div className={`${cx.dislike_btn}`}>
                          <button>
                            <AiOutlineDislike />
                          </button>
                        </div>
                        <div className={`${cx.reply_btn}`}>
                          <Button>Reply</Button>
                        </div>
                        <div className={`${cx.report_btn}`}>
                          <Button>Report Abuse</Button>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                      >
                        3 Replies
                      </Button>
                      <Collapse in={open}>
                        <div id="example-collapse-text">
                          <div className={`${cx.right_detailBox} mt-2`}>
                            <div className={`${cx.name_date}`}>
                              <h6>SHAKA@2ZKD172</h6>
                              <span>2 weeks ago</span>
                            </div>
                            <p className={`${cx.content_text}`}>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit. Et reiciendis voluptatum velit?
                              Accusantium, asperiores ex dolore nostrum adipisci
                              quia ad blanditiis nemo officiis aspernatur
                              temporibus accusamus explicabo quod, voluptates
                              deserunt.
                            </p>
                            <div className={`${cx.likeDislike_reply}`}>
                              <div className={`${cx.like_btn}`}>
                                <button>
                                  <AiOutlineLike />
                                </button>
                                <small>22</small>
                              </div>
                              <div className={`${cx.dislike_btn}`}>
                                <button>
                                  <AiOutlineDislike />
                                </button>
                              </div>
                              <div className={`${cx.reply_btn}`}>
                                <Button>Reply</Button>
                              </div>
                              <div className={`${cx.report_btn}`}>
                                <Button>Report Abuse</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>

                <hr />
                <div className={`${cx.commentsFlex}`}>
                  <div className={`${cx.left_Imgbox}`}>
                    <img src={profile} alt="profile" />
                  </div>
                  <div>
                    <div className={`${cx.right_detailBox}`}>
                      <div className={`${cx.name_date}`}>
                        <h6>SHAKA@2ZKD172</h6>
                        <span>2 weeks ago</span>
                      </div>
                      <p className={`${cx.content_text}`}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Et reiciendis voluptatum velit? Accusantium,
                        asperiores ex dolore nostrum adipisci quia ad blanditiis
                        nemo officiis aspernatur temporibus accusamus explicabo
                        quod, voluptates deserunt.
                      </p>
                      <div className={`${cx.likeDislike_reply}`}>
                        <div className={`${cx.like_btn}`}>
                          <button>
                            <AiOutlineLike />
                          </button>
                          <small>22</small>
                        </div>
                        <div className={`${cx.dislike_btn}`}>
                          <button>
                            <AiOutlineDislike />
                          </button>
                        </div>
                        <div className={`${cx.reply_btn}`}>
                          <Button>Reply</Button>
                        </div>
                        <div className={`${cx.report_btn}`}>
                          <Button>Report Abuse</Button>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                      >
                        3 Replies
                      </Button>
                      <Collapse in={open}>
                        <div id="example-collapse-text">
                          <div className={`${cx.right_detailBox} mt-2`}>
                            <div className={`${cx.name_date}`}>
                              <h6>SHAKA@2ZKD172</h6>
                              <span>2 weeks ago</span>
                            </div>
                            <p className={`${cx.content_text}`}>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit. Et reiciendis voluptatum velit?
                              Accusantium, asperiores ex dolore nostrum adipisci
                              quia ad blanditiis nemo officiis aspernatur
                              temporibus accusamus explicabo quod, voluptates
                              deserunt.
                            </p>
                            <div className={`${cx.likeDislike_reply}`}>
                              <div className={`${cx.like_btn}`}>
                                <button>
                                  <AiOutlineLike />
                                </button>
                                <small>22</small>
                              </div>
                              <div className={`${cx.dislike_btn}`}>
                                <button>
                                  <AiOutlineDislike />
                                </button>
                              </div>
                              <div className={`${cx.reply_btn}`}>
                                <Button>Reply</Button>
                              </div>
                              <div className={`${cx.report_btn}`}>
                                <Button>Report Abuse</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${cx.comments_mainBox}`}>
                <div className={`${cx.heading_top}`}>Write Your Comment</div>

                <div className={`${cx.messageForm_box}`}>
                  <form action="#">
                    <Col md={12} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Message:</label>
                      <textarea
                        className="form-control"
                        style={{ minHeight: "80px" }}
                      ></textarea>
                    </Col>
                    <button type="submit" className="btn">
                      Post
                    </button>
                  </form>
                </div>
              </div>
            </Col>

            <Col lg={4}>
              <div className={`${cx.post_list_right}`}>
                <div className={`${cx.heading_top}`}>Recommended</div>
                <NavLink className={`${cx.attach_box}`} to={"#"}>
                  <div className={`${cx.relative_box}`}>
                    <img
                      src={building}
                      alt="news"
                      className={`${cx.rightBox_img}`}
                    />
                    <div className={`${cx.tag_Box}`}>
                      <div className={`${cx.tag_line}`}>Politics</div>
                    </div>
                  </div>

                  <h3>Elections 2023: popular enthusiasm to vote</h3>
                  <ul className={`${cx.date_comments}`}>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <MdDateRange />
                        21 December, 2023
                      </div>
                    </li>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <FaRegComment />
                        06 Comments
                      </div>
                    </li>
                  </ul>

                  <p>
                    Lorem ipsum dolor sit amet, consecteturadip isicing elit,
                    sed do eiusmod tempor incididunt ed do eius.
                  </p>
                </NavLink>
                <hr />

                <NavLink className={`${cx.attach_box}`} to={"#"}>
                  <div className={`${cx.relative_box}`}>
                    <img
                      src={building}
                      alt="news"
                      className={`${cx.rightBox_img}`}
                    />
                    <div className={`${cx.tag_Box}`}>
                      <div className={`${cx.tag_line}`}>Politics</div>
                    </div>
                  </div>

                  <h3>Elections 2023: popular enthusiasm to vote</h3>
                  <ul className={`${cx.date_comments}`}>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <MdDateRange />
                        21 December, 2023
                      </div>
                    </li>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <FaRegComment />
                        06 Comments
                      </div>
                    </li>
                  </ul>

                  <p>
                    Lorem ipsum dolor sit amet, consecteturadip isicing elit,
                    sed do eiusmod tempor incididunt ed do eius.
                  </p>
                </NavLink>

                <hr />
                <NavLink to={"#"} className={`${cx.recommend_Smallbox}`}>
                  <div className={`${cx.flex_box}`}>
                    <div className={`${cx.left_sideImg}`}>
                      <img
                        src={building}
                        alt="news"
                        className={`${cx.ImgBox}`}
                      />
                    </div>
                    <div className={`${cx.right_sideDetail}`}>
                      <h4>Popular Enthusiasm to Vote Despite Some</h4>
                      <ul className={`${cx.date_comments}`}>
                        <li>
                          <div className={`${cx.flex_box}`}>
                            <MdDateRange />
                            21 December, 2023
                          </div>
                        </li>
                        <li>
                          <div className={`${cx.flex_box}`}>
                            <FaRegComment />
                            06
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </NavLink>

                <hr />
                <NavLink to={"#"} className={`${cx.recommend_Smallbox}`}>
                  <div className={`${cx.flex_box}`}>
                    <div className={`${cx.left_sideImg}`}>
                      <img
                        src={building}
                        alt="news"
                        className={`${cx.ImgBox}`}
                      />
                    </div>
                    <div className={`${cx.right_sideDetail}`}>
                      <h4>Popular Enthusiasm to Vote Despite Some</h4>
                      <ul className={`${cx.date_comments}`}>
                        <li>
                          <div className={`${cx.flex_box}`}>
                            <MdDateRange />
                            21 December, 2023
                          </div>
                        </li>
                        <li>
                          <div className={`${cx.flex_box}`}>
                            <FaRegComment />
                            06
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </NavLink>

                <hr />
                <NavLink to={"#"} className={`${cx.recommend_Smallbox}`}>
                  <div className={`${cx.flex_box}`}>
                    <div className={`${cx.left_sideImg}`}>
                      <img
                        src={building}
                        alt="news"
                        className={`${cx.ImgBox}`}
                      />
                    </div>
                    <div className={`${cx.right_sideDetail}`}>
                      <h4>Popular Enthusiasm to Vote Despite Some</h4>
                      <ul className={`${cx.date_comments}`}>
                        <li>
                          <div className={`${cx.flex_box}`}>
                            <MdDateRange />
                            21 December, 2023
                          </div>
                        </li>
                        <li>
                          <div className={`${cx.flex_box}`}>
                            <FaRegComment />
                            06
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </NavLink>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NewsDetails;
