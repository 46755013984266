import React, { useEffect } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Nav, Row, Tab } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import OffersCard from "../../../components/Website/OffersCard";
import { useLocation } from "react-router-dom";
import SoldProductCard from "../../../components/Website/SoldProductCard";

const MyOffers = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>

      <div className={`${st.header_height}`}></div>

      <section className={`${cx.offers_Section} ${cx.pageSpace}`}>
        <Container>
          <Col md={12}>
            <div className={`${cx.main_heading} text-center mb-4 pb-1`}>
              <h2>{location?.pathname === "/offers" ? 'My Offers' : 'My Sold Product'}</h2>
            </div>
          </Col>
          <Tab.Container id="left-tabs-product" defaultActiveKey="myProduct">
            <Row>
              <Col sm={12}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="myProduct">My Product</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="soldProduct">Sold Product</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="myProduct">
                    <Row>

                      <Col lg={6} md={12} className="m-auto">
                        <OffersCard />
                      </Col>
                      <Col lg={6} md={12} className="m-auto">
                        <OffersCard />
                      </Col>
                      <Col lg={6} md={12} className="m-auto">
                        <OffersCard />
                      </Col>
                      <Col lg={6} md={12} className="m-auto">
                        <OffersCard />
                      </Col>

                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="soldProduct">
                    <Row>

                      <Col lg={6} md={12} className="m-auto">
                        <SoldProductCard />
                      </Col>
                      <Col lg={6} md={12} className="m-auto">
                        <SoldProductCard />
                      </Col>
                      <Col lg={6} md={12} className="m-auto">
                        <SoldProductCard />
                      </Col>
                      <Col lg={6} md={12} className="m-auto">
                        <SoldProductCard />
                      </Col>

                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>

    </>
  );
};

export default MyOffers;
