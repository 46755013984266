import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Pagination, Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { building } from "../../../assets/images";
import { MdDateRange } from "react-icons/md";
import { FaHeart, FaRegComment } from "react-icons/fa";
import { toast } from "react-toastify";
import { NewsItem } from "../../../constants/Interface";
import { NewsCard } from "../../../components/Website/NewsCard/NewsCard";

const News = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  

  return (
    <>
      <section className={`${cx.create_register}`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={`${cx.main_heading}`}>
                <h1>News</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <NewsCard cx={cx}  />
          </Row>
        </Container>
      </section>

      <section className={`${cx.otherTitles_sec} ${cx.pageSpace} pt-1 pb-1`}>
        <Container>
          <Row>
            <Col md={8}>
              <div className={`${cx.otherTitles_main}`}>
                <div className={`${cx.main_heading}`}>
                  <h4>Other Titles</h4>
                </div>

                <div className={`${cx.latest_post}`}>
                  <NavLink to={"#"} className={`${cx.postAttachment}`}>
                    <Row className="align-items-center">
                      <Col md={5}>
                        <div className={`${cx.post_left}`}>
                          <img
                            src={building}
                            alt="news"
                            className={`${cx.apply_img}`}
                          />
                          <div className={`${cx.tag_Box}`}>
                            <div className={`${cx.tag_line}`}>Politics</div>
                          </div>
                        </div>
                      </Col>
                      <Col md={7}>
                        <div className={`${cx.details_right}`}>
                          <h3>
                            Elections 2023: popular enthusiasm to vote despite
                            technical
                          </h3>
                          <ul className={`${cx.date_comments}`}>
                            <li>
                              <div className={`${cx.flex_box}`}>
                                <MdDateRange />
                                21 December, 2023
                              </div>
                            </li>
                            <li>
                              <div className={`${cx.flex_box}`}>
                                <FaRegComment />
                                06 Comments
                              </div>
                            </li>
                          </ul>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </NavLink>
                </div>
                <div className={`${cx.latest_post}`}>
                  <NavLink to={"#"} className={`${cx.postAttachment}`}>
                    <Row className="align-items-center">
                      <Col md={5}>
                        <div className={`${cx.post_left}`}>
                          <img
                            src={building}
                            alt="news"
                            className={`${cx.apply_img}`}
                          />
                          <div className={`${cx.tag_Box}`}>
                            <div className={`${cx.tag_line}`}>Politics</div>
                          </div>
                        </div>
                      </Col>
                      <Col md={7}>
                        <div className={`${cx.details_right}`}>
                          <h3>
                            Elections 2023: popular enthusiasm to vote despite
                            technical
                          </h3>
                          <ul className={`${cx.date_comments}`}>
                            <li>
                              <div className={`${cx.flex_box}`}>
                                <MdDateRange />
                                21 December, 2023
                              </div>
                            </li>
                            <li>
                              <div className={`${cx.flex_box}`}>
                                <FaRegComment />
                                06 Comments
                              </div>
                            </li>
                          </ul>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </NavLink>
                </div>
                <div className={`${cx.latest_post}`}>
                  <NavLink to={"#"} className={`${cx.postAttachment}`}>
                    <Row className="align-items-center">
                      <Col md={5}>
                        <div className={`${cx.post_left}`}>
                          <img
                            src={building}
                            alt="news"
                            className={`${cx.apply_img}`}
                          />
                          <div className={`${cx.tag_Box}`}>
                            <div className={`${cx.tag_line}`}>Politics</div>
                          </div>
                        </div>
                      </Col>
                      <Col md={7}>
                        <div className={`${cx.details_right}`}>
                          <h3>
                            Elections 2023: popular enthusiasm to vote despite
                            technical
                          </h3>
                          <ul className={`${cx.date_comments}`}>
                            <li>
                              <div className={`${cx.flex_box}`}>
                                <MdDateRange />
                                21 December, 2023
                              </div>
                            </li>
                            <li>
                              <div className={`${cx.flex_box}`}>
                                <FaRegComment />
                                06 Comments
                              </div>
                            </li>
                          </ul>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </NavLink>
                </div>
                <div className={`${cx.latest_post}`}>
                  <NavLink to={"#"} className={`${cx.postAttachment}`}>
                    <Row className="align-items-center">
                      <Col md={5}>
                        <div className={`${cx.post_left}`}>
                          <img
                            src={building}
                            alt="news"
                            className={`${cx.apply_img}`}
                          />
                          <div className={`${cx.tag_Box}`}>
                            <div className={`${cx.tag_line}`}>Politics</div>
                          </div>
                        </div>
                      </Col>
                      <Col md={7}>
                        <div className={`${cx.details_right}`}>
                          <h3>
                            Elections 2023: popular enthusiasm to vote despite
                            technical
                          </h3>
                          <ul className={`${cx.date_comments}`}>
                            <li>
                              <div className={`${cx.flex_box}`}>
                                <MdDateRange />
                                21 December, 2023
                              </div>
                            </li>
                            <li>
                              <div className={`${cx.flex_box}`}>
                                <FaRegComment />
                                06 Comments
                              </div>
                            </li>
                          </ul>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </NavLink>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.pageSpace} pt-1 `}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={`${cx.paginationBox} mt-4`}>
                <Pagination>
                  <Pagination.First />
                  <Pagination.Prev />
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Item active>{2}</Pagination.Item>
                  <Pagination.Item disabled>{3}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{20}</Pagination.Item>
                  <Pagination.Next />
                  <Pagination.Last />
                </Pagination>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default News;
