import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Col, Container, Row, Button, Table, Collapse } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { uploadIcon, google, building, profile } from "../../../assets/images";
import SellerDetails from "../../../components/Modals/SellerDetails";
import RatingReview from "../../../components/Modals/RatingReview";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";

const ProductDetails = (props: any) => {
  const location = useLocation();
  console.log(location, "location12345");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [open, setOpen] = useState(false);
  const [showSellerDetails, setSellerDetailsShow] = useState(false);
  const handleSellerDetailsClose = () => setSellerDetailsShow(false);
  const handleSellerDetailsShow = () => setSellerDetailsShow(true);

  const [showRatingReview, setRatingReviewShow] = useState(false);
  const handleRatingReviewClose = () => setRatingReviewShow(false);
  const handleRatingReviewShow = () => setRatingReviewShow(true);

  const [commentBox, setCommentBox] = useState(false);

  const images: any = [uploadIcon, google, building];

  return (
    <>
      <section className={`${cx.create_register}`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={`${cx.main_heading}`}>
                <h1>
                  {location?.pathname === "/product-details"
                    ? "Product Detail"
                    : "Post An Ad"}
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className={`${cx.profileSection}`}>
        <Container>
          <Row>
            <Col
              xl={6}
              lg={7}
              md={9}
              sm={12}
              // style={{ margin: '0 auto' }}
            >
              <div className={`${cx.profileBox}`}>
                <div className={`${cx.left}`}>
                  <img src={profile} alt="user-review" />
                  <div className={`${cx.details}`}>
                    <h6>Posted by</h6>
                    <h5>Naveen Bansal</h5>
                  </div>
                </div>

                <div className={`${cx.right}`}>
                  <NavLink
                    to={"#"}
                    onClick={handleRatingReviewShow}
                    className="btn"
                  >
                    Give Rating & Review
                  </NavLink>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <section className={`${cx.categories_slider} ${st.spb} `}>
        <Container>
          <Row>
            <Col md={6}>
              <Carousel>
                {images.map((URL: any, index: any) => (
                  <div key={index} className={`${cx.sliderImage}`}>
                    <img src={URL} alt={`Slide ${index}`} />
                  </div>
                ))}
              </Carousel>
            </Col>

            <Col md={6}>
              <Col xl={9} lg={11} col-md-12 className="m-auto">
                <div className={`${cx.table_specifyBox}`}>
                  <h3>Specification</h3>
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td>Year</td>
                        <td>
                          <b>2010</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Category</td>
                        <td>
                          <b>Loren ispum</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Model</td>
                        <td>
                          <b>Loren ispum</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Body style</td>
                        <td>
                          <b>Lorem ispin doalt</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Condition</td>
                        <td>
                          <b>Lorem ispin doalt</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Mileage</td>
                        <td>
                          <b>Lorem ispin doalt</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Engine</td>
                        <td>
                          <b>Lorem ispin doalt</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Fuel Type</td>
                        <td>
                          <b>Lorem ispin doalt</b>
                        </td>
                      </tr>
                      <tr>
                        <td>External color</td>
                        <td>
                          <b>Lorem ispin doalt</b>
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <div
                    className={`${cx.postBtn} flex-column gap-3 flex-sm-column flex-md-column flex-lg-row flex-xl-row`}
                  >
                    <NavLink
                      onClick={handleSellerDetailsShow}
                      className="btn"
                      to={"#"}
                    >
                      {location?.pathname === "/product-details"
                        ? "Get Seller Details"
                        : "Post an Add"}
                    </NavLink>
                    {location?.pathname === "/product-details" && (
                      <NavLink className="btn sellerBtn" to={"#"}>
                        Chat with Seller
                      </NavLink>
                    )}
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.reviewSection}`}>
        <Container>
          <Row>
            <Col md="12" sm="12">
              <div className={`${cx.productDescription}`}>
                <Tabs
                  defaultActiveKey="Item_Info"
                  id="uncontrolled-tab-example"
                  className={`mb-4`}
                >
                  <Tab eventKey="Item_Info" title="Additional Information">
                    <div className={`${cx.content}`}>
                      <h5>Know your vehicle’s year, make, and model</h5>
                      <p>
                        You begin with a text, you sculpt information, you
                        chisel away what's not needed, you come to the point,
                        make things clear, add value, you're a content person,
                        you like words. Design is no afterthought, far from it,
                        but it comes in a deserved second.
                      </p>
                      <Row>
                        <Col md={6}>
                          <Table className="table-striped table-bordered">
                            <tbody>
                              <tr>
                                <td>ABS</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>LED Daytime running lights</td>
                                <td>No</td>
                              </tr>
                              <tr>
                                <td>Full LED headlights</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Outside mirror electric</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Tinted rear windows</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Centre arm rest</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Driver drowsiness detection</td>
                                <td>No</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                        <Col md={6}>
                          <Table className="table-striped table-bordered">
                            <tbody>
                              <tr>
                                <td>Digital radio</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Isofixs</td>
                                <td>No</td>
                              </tr>
                              <tr>
                                <td>Onboard computer</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Central locking</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Driver drowsiness detection</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Keyless central locking</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Lane departure warning system</td>
                                <td>No</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </div>
                  </Tab>
                  <Tab eventKey="Description" title="Overview">
                    <div className={`${cx.content}`}>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Dolore necessitatibus impedit praesentium modi
                        accusantium alias quo ipsam, porro laboriosam culpa
                        velit quia ab molestiae beatae veritatis inventore
                        dolores mollitia autem!. Lorem ipsum dolor sit amet
                        consectetur adipisicing elit. Exercitationem natus
                        reiciendis vel illo ducimus assumenda suscipit
                        accusantium accusamus culpa animi ad sed, incidunt odio
                        magni at? Debitis atque quas eum.
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Dolore necessitatibus impedit praesentium modi
                        accusantium alias quo ipsam, porro laboriosam culpa
                        velit quia ab molestiae beatae veritatis inventore
                        dolores mollitia autem!. Lorem ipsum dolor sit amet
                        consectetur adipisicing elit. Exercitationem natus
                        reiciendis vel illo ducimus assumenda suscipit
                        accusantium accusamus culpa animi ad sed, incidunt odio
                        magni at? Debitis atque quas eum.
                      </p>
                    </div>
                  </Tab>
                  {location?.pathname !== "/post-ad" && (
                    <Tab eventKey="Reviews" title="Reviews (1)">
                      <Button
                        onClick={() => {
                          setCommentBox(true);
                        }}
                      >
                        +Add Comments
                      </Button>

                      {commentBox && (
                        <div className={`${cx.addMessage_send_box}`}>
                          <input type="text" className="form-control" />
                          <button
                            type="submit"
                            className="btn"
                            onClick={() => {
                              setCommentBox(false);
                            }}
                          >
                            Send
                          </button>
                        </div>
                      )}

                      <div className={`${cx.comments_mainBox}`}>
                        <div className={`${cx.heading_top}`}>Comments</div>
                        <div className={`${cx.commentsFlex}`}>
                          <div className={`${cx.left_Imgbox}`}>
                            <img src={profile} alt="profile" />
                          </div>
                          <div>
                            <div className={`${cx.right_detailBox}`}>
                              <div className={`${cx.name_date}`}>
                                <h6>SHAKA@2ZKD172</h6>
                                <span>2 weeks ago</span>
                              </div>
                              <p className={`${cx.content_text}`}>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Et reiciendis voluptatum
                                velit? Accusantium, asperiores ex dolore nostrum
                                adipisci quia ad blanditiis nemo officiis
                                aspernatur temporibus accusamus explicabo quod,
                                voluptates deserunt.
                              </p>
                              <div className={`${cx.likeDislike_reply}`}>
                                <div className={`${cx.like_btn}`}>
                                  <button>
                                    <AiOutlineLike />
                                  </button>
                                  <small>22</small>
                                </div>
                                <div className={`${cx.dislike_btn}`}>
                                  <button>
                                    <AiOutlineDislike />
                                  </button>
                                </div>
                                <div className={`${cx.reply_btn}`}>
                                  <Button>Reply</Button>
                                </div>
                                <div className={`${cx.report_btn}`}>
                                  <Button>Report Abuse</Button>
                                </div>
                              </div>

                              <div className={`${cx.addMessage_send_box}`}>
                                <input type="text" className="form-control" />
                                <button
                                  type="submit"
                                  className="btn"
                                  onClick={() => {
                                    setCommentBox(false);
                                  }}
                                >
                                  Send
                                </button>
                              </div>
                            </div>

                            <div className="mt-3">
                              <Button
                                onClick={() => setOpen(!open)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                              >
                                3 Replies
                              </Button>
                              <Collapse in={open}>
                                <div id="example-collapse-text">
                                  <div className={`${cx.right_detailBox} mt-2`}>
                                    <div className={`${cx.name_date}`}>
                                      <h6>SHAKA@2ZKD172</h6>
                                      <span>2 weeks ago</span>
                                    </div>
                                    <p className={`${cx.content_text}`}>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipisicing elit. Et reiciendis voluptatum
                                      velit? Accusantium, asperiores ex dolore
                                      nostrum adipisci quia ad blanditiis nemo
                                      officiis aspernatur temporibus accusamus
                                      explicabo quod, voluptates deserunt.
                                    </p>
                                    <div className={`${cx.likeDislike_reply}`}>
                                      <div className={`${cx.like_btn}`}>
                                        <button>
                                          <AiOutlineLike />
                                        </button>
                                        <small>22</small>
                                      </div>
                                      <div className={`${cx.dislike_btn}`}>
                                        <button>
                                          <AiOutlineDislike />
                                        </button>
                                      </div>
                                      <div className={`${cx.reply_btn}`}>
                                        <Button>Reply</Button>
                                      </div>
                                      <div className={`${cx.report_btn}`}>
                                        <Button>Report Abuse</Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          </div>
                        </div>

                        <hr />
                        <div className={`${cx.commentsFlex}`}>
                          <div className={`${cx.left_Imgbox}`}>
                            <img src={profile} alt="profile" />
                          </div>
                          <div>
                            <div className={`${cx.right_detailBox}`}>
                              <div className={`${cx.name_date}`}>
                                <h6>SHAKA@2ZKD172</h6>
                                <span>2 weeks ago</span>
                              </div>
                              <p className={`${cx.content_text}`}>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Et reiciendis voluptatum
                                velit? Accusantium, asperiores ex dolore nostrum
                                adipisci quia ad blanditiis nemo officiis
                                aspernatur temporibus accusamus explicabo quod,
                                voluptates deserunt.
                              </p>
                              <div className={`${cx.likeDislike_reply}`}>
                                <div className={`${cx.like_btn}`}>
                                  <button>
                                    <AiOutlineLike />
                                  </button>
                                  <small>22</small>
                                </div>
                                <div className={`${cx.dislike_btn}`}>
                                  <button>
                                    <AiOutlineDislike />
                                  </button>
                                </div>
                                <div className={`${cx.reply_btn}`}>
                                  <Button>Reply</Button>
                                </div>
                                <div className={`${cx.report_btn}`}>
                                  <Button>Report Abuse</Button>
                                </div>
                              </div>
                            </div>

                            <div className="mt-3">
                              <Button
                                onClick={() => setOpen(!open)}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                              >
                                3 Replies
                              </Button>
                              <Collapse in={open}>
                                <div id="example-collapse-text">
                                  <div className={`${cx.right_detailBox} mt-2`}>
                                    <div className={`${cx.name_date}`}>
                                      <h6>SHAKA@2ZKD172</h6>
                                      <span>2 weeks ago</span>
                                    </div>
                                    <p className={`${cx.content_text}`}>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipisicing elit. Et reiciendis voluptatum
                                      velit? Accusantium, asperiores ex dolore
                                      nostrum adipisci quia ad blanditiis nemo
                                      officiis aspernatur temporibus accusamus
                                      explicabo quod, voluptates deserunt.
                                    </p>
                                    <div className={`${cx.likeDislike_reply}`}>
                                      <div className={`${cx.like_btn}`}>
                                        <button>
                                          <AiOutlineLike />
                                        </button>
                                        <small>22</small>
                                      </div>
                                      <div className={`${cx.dislike_btn}`}>
                                        <button>
                                          <AiOutlineDislike />
                                        </button>
                                      </div>
                                      <div className={`${cx.reply_btn}`}>
                                        <Button>Reply</Button>
                                      </div>
                                      <div className={`${cx.report_btn}`}>
                                        <Button>Report Abuse</Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  )}
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <SellerDetails
        show={showSellerDetails}
        handleSellerDetailsClose={handleSellerDetailsClose}
      />
      <RatingReview
        show={showRatingReview}
        handleRatingReviewClose={handleRatingReviewClose}
      />
    </>
  );
};

export default ProductDetails;
