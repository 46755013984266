import React from "react";
import { NavLink } from "react-router-dom"; // Make sure to import any necessary dependencies
import { FaStar, FaClock, FaEye } from "react-icons/fa"; // Import necessary icons
import { product } from "../../../assets/images";
import cx from "./index.module.scss";
import { IoLocationSharp } from "react-icons/io5";
import { Row, Col } from "react-bootstrap";

const SoldProductCard = () => {
  return (
    <NavLink to={"#"} className={`${cx.offer_main_box} mb-4`}>
      <Row className="align-items-center">
        <Col lg={5} md={6}>
          <div className={`${cx.offer_imgBox}`}>
            <img src={product} alt="offers" />
          </div>
        </Col>
        <Col lg={7} md={6}>
          <div className={`${cx.contentArea}`}>
            <div className={`${cx.timing_heart}`}>
              <div className={`${cx.time}`}>
                <FaClock />
                16 Dec 2023
              </div>
            </div>
            <h3>Lorem ispum doar sit</h3>
            <div className={`${cx.headLine}`}>
              <h4>$109.00 USD</h4>
              <div className={`${cx.rating}`}>
                <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </div>
            </div>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
              debitis reiciendis voluptatibus rem dignissimos. Ratione, sequi?
            </p>
            <div className={`${cx.location_views}`}>
              <div className={`${cx.location}`}>
                <IoLocationSharp /> Lorem sit met dolar sit
              </div>
              <div className={`${cx.views}`}>
                <FaEye /> 150
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </NavLink>
  );
};

export default SoldProductCard;
