import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import { Checkbox } from "../../../components/Website/Forms";
import { useForm } from "react-hook-form";
import JobCard from "../../../components/Website/JobCard/JobCard";

const Job = () => {
  const [jobList, setJobList] = useState([]);
  const [subjectArea, setSubjectArea] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [contractTypes, setContractType] = useState([]);
  const [regions, setRegions] = useState([]);
  /**
   * Search Functionality
   */

  const { register, handleSubmit } = useForm();

  const onSubmit = ({
    subject_area,
    industry,
    level_of_education,
    contract_type,
    region,
  }: any) => {
    // Handle the form submission
    getJobList({
      subject_area: subject_area ?? "",
      industry: industry ?? "",
      level_of_education: level_of_education ?? "",
      contract_type: contract_type ?? "",
      region: region ?? "",
    });
  };

  async function getSubjectArea() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/subject-areas/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setSubjectArea(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  async function getIndustry() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/industries/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();

      setIndustries(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  async function getLevelOfEducation() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/education-levels/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();

      setEducationLevels(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  async function getContractType() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/contract-types/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();

      setContractType(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  async function getRegion() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/regions/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();

      setRegions(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  async function getJobList(
    { subject_area, industry, level_of_education, contract_type, region } = {
      subject_area: "",
      industry: "",
      level_of_education: "",
      contract_type: "",
      region: "",
    }
  ) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/jobs/?subject_area=${subject_area}&industry=${industry}&level_of_education=${level_of_education}&contract_type=${contract_type}&region=${region}`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      console.log(data, "9999999");
      setJobList(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  useEffect(() => {
    getSubjectArea();
    getIndustry();
    getLevelOfEducation();
    getContractType();
    getRegion();
    getJobList();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className={`${cx.create_register}`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={`${cx.main_heading}`}>
                <h2>Find your New Job right here</h2>
                <div className={`${cx.searchFormBody}`}>
                  <div className={`${cx.box}`}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Keyword"
                    />
                  </div>
                  {/* <div className={`${cx.box}`}>
                    <select className="form-select">
                      <option>Subject Area</option>
                    </select>
                  </div> */}
                  <div className={`${cx.box} ${cx.radiusForm}`}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City or Postal Code"
                    />
                    <select className="form-select">
                      <option>Radius</option>
                    </select>
                  </div>
                  <button className={`${cx.searchBtn} btn`}>Search</button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.listingSection}`}>
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <div className={`${cx.filterSidebar}`}>
                <div className={`${cx.cardBox}`}>
                  <h5 className={`${cx.filterTitle}`}>Subject Area</h5>
                  <ul className={`${cx.listMenu}`}>
                    {subjectArea.map((item: any) => {
                      return (
                        <li key={item.id}>
                          <label className={`${st.checkbox}`}>
                            <Checkbox name={item.name} />{" "}
                            <div className={`${cx.name}`}>{item.name}</div>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className={`${cx.filterSidebar}`}>
                <div className={`${cx.cardBox}`}>
                  <h5 className={`${cx.filterTitle}`}>Industry</h5>
                  <ul className={`${cx.listMenu}`}>
                    {industries.map((item: any) => {
                      return (
                        <li key={item.id}>
                          <label className={`${st.checkbox}`}>
                            <Checkbox name={item.name} />{" "}
                            <div className={`${cx.name}`}>{item.name}</div>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className={`${cx.filterSidebar}`}>
                <div className={`${cx.cardBox}`}>
                  <h5 className={`${cx.filterTitle}`}>Level of education</h5>
                  <ul className={`${cx.listMenu}`}>
                    {educationLevels.map((item: any) => {
                      return (
                        <li key={item.id}>
                          <label className={`${st.checkbox}`}>
                            <Checkbox name={item.name} />{" "}
                            <div className={`${cx.name}`}>{item.name}</div>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className={`${cx.filterSidebar}`}>
                <div className={`${cx.cardBox}`}>
                  <h5 className={`${cx.filterTitle}`}>Contract</h5>
                  <ul className={`${cx.listMenu}`}>
                    {contractTypes.map((item: any) => {
                      return (
                        <li key={item.id}>
                          <label className={`${st.checkbox}`}>
                            <Checkbox name={item.name} />{" "}
                            <div className={`${cx.name}`}>{item.name}</div>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className={`${cx.filterSidebar}`}>
                <div className={`${cx.cardBox}`}>
                  <h5 className={`${cx.filterTitle}`}>Region</h5>
                  <ul className={`${cx.listMenu}`}>
                    {regions.map((item: any) => {
                      return (
                        <li key={item.id}>
                          <label className={`${st.checkbox}`}>
                            <Checkbox name={item.name} />{" "}
                            <div className={`${cx.name}`}>{item.name}</div>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={9} md={8}>
              <Row>
                <JobCard cx={cx} jobList={jobList} getJobList={getJobList} />
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Job;
