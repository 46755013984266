import React, { useEffect } from "react";
import cx from "./index.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col, Button, Form } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { uploadIcon } from "../../../assets/images";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Signup = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (data: any) => {
    console.log("kgsgdhsghgj");
    try {
      const formData = new FormData();
      formData.append("firstName", data?.firstName);
      formData.append("lastName", data?.lastName);
      formData.append("userName", data?.userName);
      // formData.append('apartmentNumber', data?.apartmentNumber);
      // formData.append('streetName', data?.streetName);
      formData.append("city", data?.city);
      formData.append("state", data?.state);
      // formData.append('zipCode', data?.zipCode);
      console.log(data, "jhjg");
      formData.append("uploadImages", data?.uploadImages[0]);
      const token = localStorage.getItem("token");

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/login_signup/user_data_register/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData, "responseta");
        toast.success("Signup successful!");

        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error(error, "Error submitting form:");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ToastContainer />
      <section className={`${cx.create_register}`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={`${cx.main_heading}`}>
                <h1>Create An Account</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col lg={11} sm={12} className={`m-auto`}>
              <div className={`${cx.formBox}`}>
                <h2>Profile Setup</h2>
                {/* <h3>Login</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Condimentum sit donec
                  pel enim
                </p> */}
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your name"
                          {...register("firstName", { required: true })}
                        />
                        {errors.firstName && (
                          <span className="text-danger">
                            First name is required
                            <span className="text-danger">*</span>
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Last Name"
                          {...register("lastName", { required: true })}
                        />
                        {errors.lastName && (
                          <span className="text-danger">
                            Last name is required
                            <span className="text-danger">*</span>
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>User Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Username"
                          {...register("userName", { required: true })}
                        />
                        {errors.userName && (
                          <span className="text-danger">
                            Username is required
                            <span className="text-danger">*</span>
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      {/* <Form.Group controlId="formFile" className="upload_img mt-md-4 pt-md-2"> */}
                      {/* <Form.Label>
                          <img src={uploadIcon} className="uploadIcon" alt="upload" />
                          Upload Images
                          <Form.Control type="file" /> */}

                      <Form.Group
                        controlId="formFile"
                        className="upload_img mt-md-4 pt-md-2"
                      >
                        <Form.Label>
                          <img
                            src={uploadIcon}
                            className="uploadIcon"
                            alt="upload"
                          />
                          Upload Images
                          <Form.Control
                            type="file"
                            {...register("uploadImages", { required: true })}
                          />
                        </Form.Label>
                        {errors.image && (
                          <span className="text-danger">Image is required</span>
                        )}
                      </Form.Group>
                    </Col>
                    {/* <Col md={12}>
                      <h3>Address</h3>
                    </Col> */}
                    {/* <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Apartment Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Apartment Number"
                          {...register("apartmentNumber", { required: true })}
                        />
                        {errors.apartmentNumber && (
                          <span className="text-danger">Apartment number is required<span className="text-danger">*</span></span>
                        )}
                      </Form.Group>
                    </Col> */}
                    {/* <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Street Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Street Name"
                          {...register("streetName", { required: true })}
                        />
                        {errors.streetName && (
                          <span className="text-danger">Street name is required<span className="text-danger">*</span></span>
                        )}
                      </Form.Group>
                    </Col> */}
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your City"
                          {...register("city", { required: true })}
                        />
                        {errors.city && (
                          <span className="text-danger">
                            City is required
                            <span className="text-danger">*</span>
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your State"
                          {...register("state", { required: true })}
                        />
                        {errors.state && (
                          <span className="text-danger">
                            State is required
                            <span className="text-danger">*</span>
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    {/* <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Zip Code"
                          {...register("zipCode", { required: true })}
                        />
                        {errors.zipCode && (
                          <span className="text-danger">Zip code is required<span className="text-danger">*</span></span>
                        )}
                      </Form.Group>
                    </Col> */}
                    <div className="col-md-2 mb-2">
                      <Button
                        className="btn save"
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Submit
                      </Button>
                      {/* <NavLink to={"/"} className="btn save" >Submit</NavLink> */}
                    </div>
                    <div className="col-md-2 mb-2">
                      <NavLink to={"/CreateAccount"} className="btn cancel">
                        Back
                      </NavLink>
                    </div>
                  </Row>
                </Form>
                {/* <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.Controlname2"
                      >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="Your name" />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.Controlname2"
                      >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="name"
                          placeholder="Your Last Name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.Controlname2"
                      >
                        <Form.Label>User Name</Form.Label>
                        <Form.Control type="text" placeholder="Your Username" />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        controlId="formFile"
                        className="upload_img mt-md-4 pt-md-2"
                      >
                        <Form.Label>
                          <img
                            src={uploadIcon}
                            className="uploadIcon"
                            alt="upload"
                          />{" "}
                          Upload Images
                          <Form.Control type="file" />
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <h3>Address</h3>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.input1"
                      >
                        <Form.Label>Apartment Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Apartment Number"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.inputname2"
                      >
                        <Form.Label>Street Name</Form.Label>
                        <Form.Control
                          type="Text"
                          placeholder="Your Street Name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.input1"
                      >
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" placeholder="Your City" />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.input1"
                      >
                        <Form.Label>State</Form.Label>
                        <Form.Control type="text" placeholder="Your State" />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.input1"
                      >
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control type="text" placeholder="Your Zip Code" />
                      </Form.Group>
                    </Col>
                    <div className="col-md-2 mb-2">
                      <NavLink to={"/"} className="btn save" >Submit</NavLink>
                    </div>
                    <div className="col-md-2 mb-2">
                      <NavLink to={"/CreateAccount"} className="btn cancel">Back</NavLink>
                    </div>
                  </Row>
                </Form> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Signup;
