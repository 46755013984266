import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Container, Col, Button, Form } from "react-bootstrap";

const CreateAccount = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [setValue]);

  const onSubmit = async (data: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/login_signup/phone_email_register/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phoneNo: "+91" + data.phoneNumber,
            email: data.email,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData?.token?.access, "responseta");
        localStorage.setItem("token", responseData?.token?.access);
        navigate("/CreateAccountOTP");
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData?.msg);
      }
    } catch (error) {
      console.error("Registration failed:", error);
    }
  };

  const validatePhone = (phone: string) => {
    return phone.length >= 10;
  };

  // Custom validation Function For email
  const validateEmail = (email: string) => {
    // Email Validation Using a Regular Expression in this ways
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className={`${cx.create_register}`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={`${cx.main_heading}`}>
                <h1>Create An Account</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col lg={6} md={9} sm={12} className={`m-auto`}>
              <div className={`${cx.formBox}`}>
                <Form>
                  <Form.Group className="mb-3" controlId="phone">
                    <Form.Label>
                      Phone No.<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Write your Number"
                      {...register("phoneNumber", {
                        required: true,
                        validate: validatePhone,
                      })}
                      defaultValue=""
                    />
                    {errors.phoneNumber &&
                      errors.phoneNumber.type === "required" && (
                        <span className="text-danger">
                          Phone number is required.
                          <span className="text-danger">*</span>
                        </span>
                      )}
                    {errors.phoneNumber &&
                      errors.phoneNumber.type === "validate" && (
                        <span className="text-danger">
                          Please enter a valid phone number.
                          <span className="text-danger">*</span>
                        </span>
                      )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>
                      Email<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Write your Email"
                      {...register("email", {
                        required: true,
                        validate: validateEmail,
                      })}
                      defaultValue=""
                    />
                    {errors.email && errors.email.type === "required" && (
                      <span className="text-danger">
                        Email is required.<span className="text-danger">*</span>
                      </span>
                    )}
                    {errors.email && errors.email.type === "validate" && (
                      <span className="text-danger">
                        Please enter a valid email address.
                        <span className="text-danger">*</span>
                      </span>
                    )}
                  </Form.Group>

                  <Row>
                    <div className="col-md-6 mb-2">
                      <Button
                        className="btn save"
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Submit
                      </Button>
                      {errorMessage && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      )}
                    </div>
                    <div className="col-md-6 mb-2">
                      <NavLink to={"/login"} className="btn cancel">
                        Back
                      </NavLink>
                    </div>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CreateAccount;
