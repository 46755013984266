import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Row } from "react-bootstrap";
import st from "../../../assets/stylesheet/style.module.scss";
import { Container, Col } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import {
  FaFacebookF,
  FaFacebookMessenger,
  FaHeart,
  FaLinkedinIn,
} from "react-icons/fa";
import { MdDateRange, MdPlace } from "react-icons/md";
import { FaArrowLeftLong, FaArrowRightLong, FaTwitter } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { BiDetail, BiSolidTag } from "react-icons/bi";
import { TenderItem } from "../../../constants/Interface";

const TenderDetails = () => {
  const [favorites, setFavorite] = useState(false);

  const params = useParams();
  console.log(params, "Tender Details: " + params.id);
  const [tenderDetails, setTenderDetails] = useState<TenderItem>({
    id: 0,
    title: "",
    place: "",
    body: "",
    description_type: "",
    text_description: "",
    file_description: "",
    create_time: "",
  });

  async function getTenderDetails() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/tender/tender-details/${params.id}`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      console.log(data, "Tender Details 000");
      setTenderDetails(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  useEffect(() => {
    getTenderDetails();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={`${st.header_height}`}></div>

      <section className={`${cx.pageSpace}`}>
        <Container>
          {/* <Row>
            <Col md={12}>
              <div className={`${cx.title_TopHeading}`}>
                <h4><span>Call For Tender:</span> Rental of tractors for plowing and harrowing in the Kenge and Menkao sites (76 ha including 50 ha in Kenge and 26 ha in Menkao)</h4>
              </div>
            </Col>
          </Row> */}

          <Row>
            <Col md={11} className="m-auto">
              <div className={`${cx.post_list_left}`}>
                <div className={`${cx.post_wrapper}`}>
                  {/* <img src={building} alt="news" className={`${cx.Top_img}`} /> */}
                  <div className={`${cx.content_details}`}>
                    <div className={`${cx.tag_line}`}>Call For Tender:</div>

                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <ul className={`${cx.share_icons}`}>
                        <li>
                          <NavLink to={"#"}>
                            <FaFacebookF />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <FaLinkedinIn />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <FaFacebookMessenger />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <FaTwitter />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={"#"}>
                            <IoMdMail />
                          </NavLink>
                        </li>
                      </ul>

                      <div className={`${cx.addFavorite}`}>
                        {/* <NavLink className={`${cx.chatBtn}`} to={"#"}>
                          Chat
                        </NavLink> */}


                        <div className={`${cx.heart}`}>
                          <button
                            className={`${favorites ? cx.active : ""}`}
                            onClick={() => {
                              setFavorite(true);
                            }}

                          >
                            <FaHeart /> Add To Favorites
                          </button>
                        </div>

                      </div>
                    </div>
                    <h3>{tenderDetails.title}</h3>
                    <ul className={`${cx.date_comments}`}>
                      <span>
                        {" "}
                        <BiSolidTag className="tender_tag progressClr" />{" "}
                        <small>
                          <b>Published On:</b>
                        </small>
                      </span>
                      <li>
                        <div className={`${cx.flex_box}`}>
                          <MdDateRange />
                          {new Date(tenderDetails.create_time!).toDateString()}
                        </div>
                      </li>
                    </ul>

                    <hr />
                    <div className={`${cx.place_bodyTender}`}>
                      <h6>
                        <span>
                          <MdPlace /> Place:
                        </span>{" "}
                        {tenderDetails.place}
                      </h6>
                      <h6>
                        <span>
                          <BiDetail /> Body:
                        </span>{" "}
                        {tenderDetails.body}
                      </h6>
                    </div>

                    <div className={`${cx.para_box}`}>
                      {tenderDetails.description_type === "file" && (
                        <div>
                          <img
                            className={`${cx.tender_detailImg}`}
                            src={tenderDetails.file_description}
                            alt="details"
                          />
                        </div>
                      )}

                      {tenderDetails.description_type === "text" && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: tenderDetails.text_description!,
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className={`${cx.content_details}`}>
                    <div className={`${cx.navigation_wrapBtn}`}>
                      <NavLink to={"#"} className={`${cx.prev_next}`}>
                        <FaArrowLeftLong /> Prev Post
                      </NavLink>
                      <NavLink to={"#"} className={`${cx.prev_next}`}>
                        Next Post <FaArrowRightLong />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            {/* <Col md={4}>
              <div className={`${cx.post_list_right}`}>
                <div className={`${cx.heading_top}`}>Most Commented</div>
                <NavLink className={`${cx.attach_box}`} to={"#"}>
                  <div className={`${cx.relative_box}`}>
                    <img src={building} alt="news" className={`${cx.rightBox_img}`} />
                    <div className={`${cx.tag_Box}`}>
                      <div className={`${cx.tag_line}`}>
                        Politics
                      </div>
                    </div>
                  </div>

                  <h3>Elections 2023: popular enthusiasm to vote</h3>
                  <ul className={`${cx.date_comments}`}>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <MdDateRange />
                        21 December, 2023
                      </div>
                    </li>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <FaRegComment />
                        06 Comments
                      </div>
                    </li>
                  </ul>

                  <p>Lorem ipsum dolor sit amet, consecteturadip isicing elit, sed do eiusmod
                    tempor incididunt ed do eius.</p>
                </NavLink>
                <hr />

                <NavLink className={`${cx.attach_box}`} to={"#"}>
                  <div className={`${cx.relative_box}`}>
                    <img src={building} alt="news" className={`${cx.rightBox_img}`} />
                    <div className={`${cx.tag_Box}`}>
                      <div className={`${cx.tag_line}`}>
                        Politics
                      </div>
                    </div>
                  </div>

                  <h3>Elections 2023: popular enthusiasm to vote</h3>
                  <ul className={`${cx.date_comments}`}>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <MdDateRange />
                        21 December, 2023
                      </div>
                    </li>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <FaRegComment />
                        06 Comments
                      </div>
                    </li>
                  </ul>

                  <p>Lorem ipsum dolor sit amet, consecteturadip isicing elit, sed do eiusmod
                    tempor incididunt ed do eius.</p>
                </NavLink>
                <hr />

                <NavLink className={`${cx.attach_box}`} to={"#"}>
                  <div className={`${cx.relative_box}`}>
                    <img src={building} alt="news" className={`${cx.rightBox_img}`} />
                    <div className={`${cx.tag_Box}`}>
                      <div className={`${cx.tag_line}`}>
                        Politics
                      </div>
                    </div>
                  </div>

                  <h3>Elections 2023: popular enthusiasm to vote</h3>
                  <ul className={`${cx.date_comments}`}>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <MdDateRange />
                        21 December, 2023
                      </div>
                    </li>
                    <li>
                      <div className={`${cx.flex_box}`}>
                        <FaRegComment />
                        06 Comments
                      </div>
                    </li>
                  </ul>

                  <p>Lorem ipsum dolor sit amet, consecteturadip isicing elit, sed do eiusmod
                    tempor incididunt ed do eius.</p>
                </NavLink>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TenderDetails;
