import React, { useEffect } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Container, Row, Pagination } from "react-bootstrap";
import ProductCard from "../../../components/Website/ProductCard";
import FilterSidebar from "../../../components/Website/FilterSidebar";

const AllCategory = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={`${st.header_height}`}></div>

      <section className={`${cx.select_dropdownMain} ${st.spb} pb-0`}>
        <Container>
          <Row>
            <Col md={4}>
              <div className={`${cx.select_dropdown_box}`}>
                <label>Categories</label>
                <select className="form-select">
                  <option value="0">Select Categories</option>
                  <option value="1">All Categories 1</option>
                  <option value="1">All Categories 2</option>
                  <option value="1">All Categories 3</option>
                  <option value="1">All Categories 4</option>
                  <option value="1">All Categories 5</option>
                  <option value="1">All Categories 6</option>
                  <option value="1">All Categories 7</option>
                  <option value="1">All Categories 8</option>
                  <option value="1">All Categories 9</option>
                </select>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.rentList_Sides} ${st.spb}`}>
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <FilterSidebar />
            </Col>
            <Col lg={9} md={8}>
              <Row>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <div className={`${cx.paginationBox} mt-4`}>
                <Pagination>
                  <Pagination.First />
                  <Pagination.Prev />
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Item active>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                  <Pagination.Item disabled>{4}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{20}</Pagination.Item>
                  <Pagination.Next />
                  <Pagination.Last />
                </Pagination>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AllCategory;
