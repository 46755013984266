import React from "react";
import m from "./Modal.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from 'react-icons/md';
import { FaRegStar, FaStarHalfAlt, FaStar } from "react-icons/fa";

const RatingReview = (props: any) => {
  let { show, handleRatingReviewClose } = props;
  return (
    <>
      <Modal
        centered
        size="sm"
        show={show}
        onHide={handleRatingReviewClose}
        aria-labelledby="example-modal-sizes-title-sm" className={m.modalCts}
      >
        <Modal.Header>
          <Modal.Title><b>Rating & Review</b></Modal.Title>
          <button className={`${m.closeIcon}`} onClick={handleRatingReviewClose}><MdClose /></button>
        </Modal.Header>
        <Modal.Body style={{backgroundColor: "#f8f8f8"}}>
          <Row>
            <Col md={12} className="mb-4">
              <ul className={`${m.ratingStar}`}>
                <div className={`${m.labelling}`}>How was quality of the listing?</div>
                <li>
                  <FaStar />
                  <FaStarHalfAlt />
                  <FaRegStar />
                  <FaRegStar />
                  <FaRegStar />
                </li>
              </ul>
            </Col>

            <Col md={12}>
              <div className={`${m.reviewSec}`}>
                <div className="form-group">
                  <textarea className="form-control" placeholder="Leave a message..." id="eTextarea1" rows={3}></textarea>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className={`btn w-100 ${m.actionBtn}`}>
              Post
            </button>
          </Modal.Footer>

      </Modal>
    </>
  );
};

export default RatingReview;