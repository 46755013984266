import React, { useEffect } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { usaid } from "../../../assets/images";
import { Col, Container, Row, Pagination } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ProductCard from "../../../components/Website/ProductCard";
import FilterSidebar from "../../../components/Website/FilterSidebar";
import { HiBuildingOffice2, HiOutlineHome } from "react-icons/hi2";
import { TfiCar } from "react-icons/tfi";
import { FaPhotoVideo } from "react-icons/fa";
import { BiSolidOffer } from "react-icons/bi";
import { GiClothes } from "react-icons/gi";
import { IoNewspaperOutline } from "react-icons/io5";
import { TbIdBadge } from "react-icons/tb";

const Home1 = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options1 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 3,
      },
      992: {
        items: 5,
      },
    },
  };

  const options2 = {
    loop: false,
    items: 4,
    dots: false,
    nav: true,
    navText: [
      `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`,
      `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`,
    ],
    autoplay: false,
    responsive: {
      0: {
        items: 2,
      },
      480: {
        items: 2,
      },
      768: {
        items: 4,
      },
      992: {
        items: 8,
      },
    },
  };

  return (
    <>
      <div className={`${st.header_height}`}></div>

      <section className={`${cx.filter_topSlider} ${st.spb} pb-0`}>
        <Container>
          <div className={`${cx.slideMain_box}`}>
            <div className={`${cx.left}`}>
              <OwlCarousel className="owl-theme" {...options2}>
                <div className="item">
                  <NavLink to={"#"} className={`${cx.boxes} ${cx.active}`}>
                    <HiOutlineHome />
                    <p>Immovable</p>
                  </NavLink>
                </div>
                <div className="item">
                  <NavLink to={"#"} className={`${cx.boxes}`}>
                    <TfiCar />
                    <p>Car</p>
                  </NavLink>
                </div>
                <div className="item">
                  <NavLink to={"#"} className={`${cx.boxes}`}>
                    <FaPhotoVideo />
                    <p>Multimedia</p>
                  </NavLink>
                </div>
                <div className="item">
                  <NavLink to={"#"} className={`${cx.boxes}`}>
                    <GiClothes />
                    <p>Clothes</p>
                  </NavLink>
                </div>
                <div className="item">
                  <NavLink to={"#"} className={`${cx.boxes}`}>
                    <BiSolidOffer />
                    <p>Offers</p>
                  </NavLink>
                </div>
                <div className="item">
                  <NavLink to={"#"} className={`${cx.boxes}`}>
                    <HiBuildingOffice2 />
                    <p>Jobs</p>
                  </NavLink>
                </div>
                <div className="item">
                  <NavLink to={"#"} className={`${cx.boxes}`}>
                    <TbIdBadge />
                    <p>Tenders</p>
                  </NavLink>
                </div>
                <div className="item">
                  <NavLink to={"#"} className={`${cx.boxes}`}>
                    <IoNewspaperOutline />
                    <p>News</p>
                  </NavLink>
                </div>
                <div className="item">
                  <NavLink to={"#"} className={`${cx.boxes}`}>
                    <HiOutlineHome />
                    <p>See All</p>
                  </NavLink>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </Container>
      </section>

      <section className={`${cx.rentList_Sides} ${st.spb}`}>
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <FilterSidebar />
            </Col>
            <Col lg={9} md={8}>
              <Row>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
                <Col lg={4} md={6} className="mb-4">
                  <ProductCard />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <div className={`${cx.paginationBox} mt-4`}>
                <Pagination>
                  <Pagination.First />
                  <Pagination.Prev />
                  <Pagination.Item>{1}</Pagination.Item>
                  <Pagination.Item active>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                  <Pagination.Item disabled>{4}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{20}</Pagination.Item>
                  <Pagination.Next />
                  <Pagination.Last />
                </Pagination>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section
        className={`${cx.suggestAddsSec_slider} my-4 py-4 mb-5 border-top border-bottom`}
      >
        <Container>
          <Col md={12}>
            <div className="text-center mb-5">
              <h2>Advertisement</h2>
            </div>
          </Col>

          <Col md={12}>
            <OwlCarousel className="owl-theme" {...options1}>
              <div className="item">
                <div className="me-3 ms-3">
                  <img src={usaid} alt="aid" />
                </div>
              </div>
              <div className="item">
                <div className="me-3 ms-3">
                  <img src={usaid} alt="aid" />
                </div>
              </div>
              <div className="item">
                <div className="me-3 ms-3">
                  <img src={usaid} alt="aid" />
                </div>
              </div>
              <div className="item">
                <div className="me-3 ms-3">
                  <img src={usaid} alt="aid" />
                </div>
              </div>
              <div className="item">
                <div className="me-3 ms-3">
                  <img src={usaid} alt="aid" />
                </div>
              </div>
              <div className="item">
                <div className="me-3 ms-3">
                  <img src={usaid} alt="aid" />
                </div>
              </div>
              <div className="item">
                <div className="me-3 ms-3">
                  <img src={usaid} alt="aid" />
                </div>
              </div>
            </OwlCarousel>
          </Col>
        </Container>
      </section>
    </>
  );
};

export default Home1;
