import React, { useState } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap';
import { BiSolidTag } from 'react-icons/bi';
import { FaHeart } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { TenderItem } from '../../../constants/Interface';
import { toast } from 'react-toastify';

const TenderTable = ({ cx, tenderList, getTenderList }: any) => {
    const [favorites, setFavorite] = useState(false);
    const favorite = async (id: number) => {
        try {
            const formData = new FormData();
            formData.append("tender", id.toString());
            formData.append("user", JSON.parse(localStorage.getItem("userData")!).id);

            const response = await fetch(
                `${process.env.REACT_APP_BASEURL}/tender/fav_tender/`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")!}`,
                    },
                    body: formData,
                }
            );

            if (!response.ok) {
                throw new Error("Failed to submit the data. Please try again.");
            }

            // Handle response if necessary
            if (response.status === 204 && response.statusText === "No Content") {
                toast.info("Tender Removed from Favorite");
            }

            if (response.status === 201 && response.statusText === "Created") {
                const data = await response.json();
                toast.success(data.message);
            }
        } catch (error) {
            // Capture the error message to display to the user
            console.error(error);
        } finally {
            getTenderList();
        }
    };

    return (
        <div>
            <section className={`${cx.tenderBox}`}>
                <Container>
                    <Row>
                        <Col lg="12" md={12} sm="12">
                            <div className={`${cx.tanderTable}`}>
                                <ul className={`${cx.tableHeading}`}>
                                    <li>
                                        <BiSolidTag className="tender_tag progressClr" /> In
                                        progress
                                    </li>
                                    <li>
                                        <BiSolidTag className="tender_tag lessdayClr" /> Less than 3
                                        days
                                    </li>
                                    <li>
                                        <BiSolidTag className="tender_tag epxiredClr" /> Expired
                                    </li>
                                    <li>
                                        <BiSolidTag className="tender_tag unspecifiedClr" />{" "}
                                        Unspecified
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className={`${cx.tender_tableSec}`}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={`${cx.slide_scroll} table-responsive`}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Object</th>
                                            <th>Body</th>
                                            <th>Place</th>
                                            <th>Inserted</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tenderList.map(
                                            ({
                                                id,
                                                title,
                                                place,
                                                body,
                                                create_time = "",
                                            }: TenderItem) => {
                                                return (
                                                    <tr key={id}>
                                                        <td>
                                                            <BiSolidTag className="tender_tag progressClr" />
                                                        </td>
                                                        <td>
                                                            <NavLink to={`/tender-details/${id}`}>
                                                                <p>{title}</p>
                                                                {/* <span>AOF37095</span> */}
                                                            </NavLink>
                                                        </td>
                                                        <td>{body}</td>
                                                        <td>{place}</td>
                                                        <td>{new Date(create_time).toDateString()}</td>
                                                        <td
                                                            onClick={() => {
                                                                favorite(id);
                                                            }}
                                                        >
                                                            {/* <NavLink to={"#"}>
                                                                <MdStars />
                                                            </NavLink> */}
                                                            <div className={`${cx.heart} text-center`}>
                                                                <button
                                                                    className={`${favorites ? cx.active : ""}`}
                                                                    onClick={() => {
                                                                        setFavorite(true);
                                                                    }}
                                                                >
                                                                    <FaHeart />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}

                                        {/* <tr>
                                            <td>
                                                <BiSolidTag className="tender_tag lessdayClr" />
                                            </td>
                                            <td>
                                                <a href="#">
                                                    <p>Supply of seeds and packaging for food security</p>
                                                    <span>AOF37095</span>
                                                </a>
                                            </td>
                                            <td>World Vision DR Congo</td>
                                            <td>Kenge and Menkao</td>
                                            <td>21.12.2023</td>
                                            <td>
                                                <NavLink to={"#"}>
                                                    <MdStars />
                                                </NavLink>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <BiSolidTag className="tender_tag epxiredClr" />
                                            </td>
                                            <td>
                                                <a href="#">
                                                    <p>
                                                        Supply of seeds and packaging for food security
                                                        beneficiaries of projects 1350.13 and 1381.13 (BMZ
                                                        ADI and ABA)
                                                    </p>
                                                    <span>AOF37095</span>
                                                </a>
                                            </td>
                                            <td>World Vision DR Congo</td>
                                            <td>Kenge and Menkao</td>
                                            <td>21.12.2023</td>
                                            <td>
                                                <NavLink to={"#"}>
                                                    <MdStars />
                                                </NavLink>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <BiSolidTag className="tender_tag unspecifiedClr" />
                                            </td>
                                            <td>
                                                <a href="#">
                                                    <p>
                                                        Supply of seeds and packaging for food security
                                                        beneficiaries of projects 1350.13 and 1381.13 (BMZ
                                                        ADI and ABA)
                                                    </p>
                                                    <span>AOF37095</span>
                                                </a>
                                            </td>
                                            <td>World Vision DR Congo</td>
                                            <td>Kenge and Menkao</td>
                                            <td>21.12.2023</td>
                                            <td>
                                                <NavLink to={"#"}>
                                                    <MdStars />
                                                </NavLink>
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default TenderTable