import React, { useState } from 'react'
import { Col } from 'react-bootstrap';
import { FaHeart } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

const JobCard = ({ cx, jobList, getJobList }: any) => {
    const [favorites, setFavorite] = useState(false);
    const favorite = async (id: number) => {
        try {
            const formData = new FormData();
            formData.append("job", id.toString());
            formData.append("user", JSON.parse(localStorage.getItem("userData")!).id);

            const response = await fetch(
                `${process.env.REACT_APP_BASEURL}/jobs/fav_job/`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")!}`,
                    },
                    body: formData,
                }
            );

            if (!response.ok) {
                throw new Error("Failed to submit the data. Please try again.");
            }

            // Handle response if necessary

            if (response.status === 204 && response.statusText === "No Content") {
                toast.info("Job Removed from Favorite");
            }

            if (response.status === 201 && response.statusText === "Created") {
                const data = await response.json();
                toast.success(data.message);
            }
        } catch (error) {
            // Capture the error message to display to the user
            console.error(error);
        } finally {
            getJobList();
        }
    };

    return (
        <>
            {jobList.map((job: any) => {
                return (
                    <Col lg={4} md={6} key={job?.id}>
                        <div className={`${cx.listingCard}`}>
                            <small>
                                {job.industry.name} | {job.subject_area.name}
                            </small>
                            <div className={`${cx.heart}  ${cx.starMark}`}>
                                <button 
                                    className={`${favorites ? cx.active : ""}`}
                                    onClick={() => {
                                        setFavorite(true);
                                        favorite(job.id);
                                    }}

                                >
                                    <FaHeart />
                                </button>
                            </div>
                            {/* <FaRegStar
                          className={`${cx.starMark}`}
                          onClick={() => {
                            favorite(job.id);
                          }}
                        /> */}
                            <h4>{job.job_title}</h4>
                            <p
                                dangerouslySetInnerHTML={{ __html: job.description! }}
                            ></p>
                            <NavLink
                                to={`/job-details/${job?.id}`}
                                className={`${cx.readMore}`}
                            >
                                Read more
                            </NavLink>
                        </div>
                    </Col>
                );
            })}
        </>
    )
}

export default JobCard