import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap';
import { FaRegComment, FaHeart } from 'react-icons/fa';
import { MdDateRange } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { NewsItem } from '../../../constants/Interface';
import { toast } from 'react-toastify';


export const NewsCard = ({ cx ,newsList,getNewsList,}: any) => {
    const [data, setData] = useState([]);
    const abortController = new AbortController();

    const favorite = async (news_id: number) => {
        try {
            const formData = new FormData();
            formData.append("news", news_id.toString());
            formData.append("user", JSON.parse(localStorage.getItem("userData")!).id);

            const response = await fetch(
                `${process.env.REACT_APP_BASEURL}/news/fav_news/`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")!}`,
                    },
                    body: formData,
                }
            );

            if (!response.ok) {
                throw new Error("Failed to submit the data. Please try again.");
            }

            // Handle response if necessary
            if (response.status === 204 && response.statusText === "No Content") {
                toast.info("News Removed from Favorite");
            }

            if (response.status === 201 && response.statusText === "Created") {
                const data = await response.json();
                toast.success(data.message);
            }
        } catch (error) {
            // Capture the error message to display to the user
            console.error(error);
        } finally {
            fetchData();
        }
    };

    const fetchData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASEURL}/news/news/`,
                {
                    signal: abortController.signal, // Pass the abort controller's signal to the fetch request
                }
            );
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
            const jsonData = await response.json();
            setData(jsonData);
        } catch (error: any) {
            if (!abortController.signal.aborted) {
                toast.error(error.message);
            }
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {data.map(({ id, news_image, news_article, title }: NewsItem) => (
                <Col key={id} md={6} className="mb-3">
                    <div className={`${cx.articles_main}`}>
                        <NavLink
                            to={`/news-details/${id}`}
                            className={`${cx.article_attachBox}`}
                        >
                            <img
                                src={news_image}
                                alt="news"
                                className={`${cx.apply_img}`}
                            />
                            <div className={`${cx.content_details}`}>
                                <div className={`${cx.tag_line}`}>
                                    {news_article?.name}
                                </div>
                                <h3>{title}</h3>
                                <ul className={`${cx.date_comments}`}>
                                    <li>
                                        <div className={`${cx.flex_box}`}>
                                            <MdDateRange />
                                            21 December, 2023
                                        </div>
                                    </li>
                                    <li>
                                        <div className={`${cx.flex_box}`}>
                                            <FaRegComment />
                                            06 Comments
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </NavLink>
                        <div
                            className={`${cx.addFavorite}`}
                            onClick={() => {
                                favorite(id);
                            }}
                        >
                            <button className={`${cx.active}`}>
                                <FaHeart /> Add To Favorites
                            </button>
                        </div>
                    </div>
                </Col>
            ))}
        </>
    )
}
