import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import jobcx from "../Job/index.module.scss";
import tendercx from "../Tender/index.module.scss";
import newscx from "../News/index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Nav, Row, Tab } from "react-bootstrap";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { IoNewspaperOutline } from "react-icons/io5";
import { BsPostcard } from "react-icons/bs";
import { TbIdBadge } from "react-icons/tb";
import { Container, Col } from "react-bootstrap";
import ProductCard from "../../../components/Website/ProductCard";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import JobCard from "../../../components/Website/JobCard/JobCard";
import TenderTable from "../../../components/Website/TenderTable/TenderTable";
import { NewsCard } from "../../../components/Website/NewsCard/NewsCard";
import { useNavigate } from "react-router-dom";
import { CustomError } from "../../../hooks/customError";
import { toast } from "react-toastify";

const MyFavourites = () => {
  const [jobList, setJobList] = useState([]);
  const [tenderList, setTenderList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const navigate = useNavigate();

  async function getProfile() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${
          JSON.parse(localStorage.getItem("userData")!)?.id
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
        }
      );
      console.log(response, "=========");

      if (!response.ok) {
        let errorMessage = "Failed to submit the data. Please try again.";
        let responseData;

        try {
          responseData = await response.json();
          // Extract error message from the specific structure of the error response
          errorMessage = responseData.errors.detail || errorMessage;
        } catch (parseError) {
          console.error("Failed to parse error response:", parseError);
        }

        // Check if the error indicates token expiration and status code is 403
        console.log(errorMessage, "111111111111111");
        if (
          response.status === 403 &&
          (errorMessage.toLowerCase().includes("token has expired") ||
            errorMessage.toLowerCase().includes("token is invalid"))
        ) {
          toast.error(errorMessage);
          setTimeout(() => {
            localStorage.clear();
            navigate("/login"); // Redirect to login page
          }, 500);
        }

        const error = new CustomError(errorMessage);
        error.responseData = responseData;
        throw error;
      }

      // Handle response if necessary
      const { data, favourite_jobs, favourite_news, favourite_tenders } =
        await response.json();
      setJobList(favourite_jobs);
      setTenderList(favourite_tenders);
      setNewsList(favourite_news);
      console.log(data, "000000000000000000000");
      // ...
    } catch (error: any) {
      // Capture the error message to display to the user
      console.error(error);
      console.log(error, "API request failed:");
    }
  }

  useEffect(() => {
    getProfile();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={`${st.header_height}`}></div>
      <section className={`${cx.filter_topSlider} ${st.spb} pb-0`}>
        <Container>
          <div className={`${cx.slideMain_box}`}>
            <div className={`${cx.left}`}>
              <Tab.Container
                id="left-tabs-examplefav"
                defaultActiveKey="favFirst"
              >
                <Row>
                  <Col md={12}>
                    <Nav>
                      <Nav.Item>
                        <Nav.Link eventKey="favFirst" className={`${cx.boxes}`}>
                          <BsPostcard />
                          <p>Post</p>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="favSecond"
                          className={`${cx.boxes}`}
                        >
                          <HiBuildingOffice2 />
                          <p>Jobs</p>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="favThird" className={`${cx.boxes}`}>
                          <TbIdBadge />
                          <p>Tenders</p>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="favFourth"
                          className={`${cx.boxes}`}
                        >
                          <IoNewspaperOutline />
                          <p>News</p>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col md={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="favFirst">
                        <div>
                          <div
                            className={`${cx.favouriteSection} ${cx.pageSpace}`}
                          >
                            <Row>
                              <Col md={6} lg={4} className="mb-4">
                                <ProductCard />
                              </Col>
                              <Col md={6} lg={4} className="mb-4">
                                <ProductCard />
                              </Col>
                              <Col md={6} lg={4} className="mb-4">
                                <ProductCard />
                              </Col>
                              <Col md={6} lg={4} className="mb-4">
                                <ProductCard />
                              </Col>
                              <Col md={6} lg={4} className="mb-4">
                                <ProductCard />
                              </Col>
                              <Col md={6} lg={4} className="mb-4">
                                <ProductCard />
                              </Col>
                              <Col md={6} lg={4} className="mb-4">
                                <ProductCard />
                              </Col>
                              <Col md={6} lg={4} className="mb-4">
                                <ProductCard />
                              </Col>
                              <Col md={6} lg={4} className="mb-4">
                                <ProductCard />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="favSecond">
                        <div className={`${cx.pageSpace}`}>
                          <Col lg={9} md={8}>
                            <Row>
                              <JobCard
                                cx={jobcx}
                                jobList={jobList}
                                getJobList={getProfile}
                              />
                            </Row>
                          </Col>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="favThird">
                        <div className={`${cx.pageSpace}`}>
                          <TenderTable
                            cx={tendercx}
                            tenderList={tenderList}
                            getTenderList={getProfile}
                          />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="favFourth">
                        <div className={`${cx.pageSpace}`}>
                          <Row>
                            <NewsCard
                              cx={newscx}
                              newsList={newsList}
                              getNewsList={getProfile}
                            />
                          </Row>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default MyFavourites;
