import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, Dropdown, Form } from "react-bootstrap";
import cx from "./index.module.scss";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { hamburger, profile } from "../../../assets/images";
import { IoSearchSharp } from "react-icons/io5";
import { Logout } from "../../Modals";

export default function HeaderSub() {
  const [showLogout, setLogoutShow] = useState(false);
  const [loggedIn, setLoggedIn] = useState("");
  const handleLogoutClose = () => setLogoutShow(false);
  const handleLogoutShow = () => setLogoutShow(true);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    setLoggedIn(localStorage.getItem("token")!);
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20);
    });
  }, []);

  const [dropdownOpen, setDropdownOpen] = useState<any>(false);

  const handleDropdownToggle: any = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleNavLinkClick = () => {
    setDropdownOpen(false);
    // Add any additional logic you want to execute when a NavLink is clicked
  };
  return (
    <>
      <header className={`${cx.main_header}  ${scroll ? cx.affix : ""} `}>
        <Navbar>
          <Container>
            <NavLink className="navbar-brand" to={"/"}>
              <h3 className="text-white">Logo</h3>
              {/* <img src={torchLogo} alt="logo" /> */}
            </NavLink>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              {/* <Form className="d-flex col-lg-5 m-auto my-2 my-lg-0 seach_relatedBtns">
                <Form.Control
                  type="search"
                  placeholder="Lorem ispum..."
                  className="me-2"
                  aria-label="Search"
                />
                <button className="btn"><IoSearchSharp /></button>
              </Form> */}
              <Form className="d-flex col-lg-5 m-auto my-2 my-lg-0 seach_relatedBtns">
                <div className="position-relative w-100">
                  <Form.Control
                    type="search"
                    placeholder="Search..."
                    className="me-2"
                    aria-label="Search"
                  />
                  <button className="btn">
                    <IoSearchSharp />
                  </button>
                  <select className="form-select">
                    <option value="1">All Categories</option>
                    <option value="2">All Categories 1</option>
                    <option value="3">All Categories 2</option>
                    <option value="4">All Categories 3</option>
                    <option value="5">All Categories 4</option>
                  </select>
                </div>
              </Form>
              <Nav className="align-items-center" navbarScroll>
                {/* <Dropdown show={dropdownOpen1} className="ms-2 post-dropdown">
                  <Dropdown.Toggle onClick={handleDropdownToggle1} id="dropdown1">
                    Post An Ad
                    <img className={`${cx.arrow_down}`} src={downArrow} alt="profile" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <ul>
                      <li>
                        <NavLink to={'/add-product'} onClick={handleNavLinkClick}>Product</NavLink>
                      </li>
                      <li>
                        <NavLink to={'/post-a-job'} onClick={handleNavLinkClick}>Job</NavLink>
                      </li>
                      <li>
                        <NavLink to={'/post-a-tender'} onClick={handleNavLinkClick}>Tender</NavLink>
                      </li>
                      <li>
                        <NavLink to={'/post-a-news'} onClick={handleNavLinkClick}>News</NavLink>
                      </li>
                      
                    </ul>
                  </Dropdown.Menu>

                </Dropdown> */}

                {/* <NavLink to={"/chat"} className="ms-lg-1 nav-link chat-notification"><FaRegEnvelope /> Chat <span className="notify">12</span> </NavLink> */}

                {/* <NavLink to={"/login"} className="ms-lg-1 nav-link" onClick={handleNavLinkClick1} >Login</NavLink> */}

                {/* <Dropdown className={`${cx.service_dropdown}`}>
                  <Dropdown.Toggle id="dropdown-basic1">
                    Service
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <ul>
                      <li>
                        <NavLink to={'#'}>Offers</NavLink>
                      </li>
                      <li>
                        <NavLink to={'#'}>Jobs</NavLink>
                      </li>
                      <li>
                        <NavLink to={'#'}>Tenders</NavLink>
                      </li>
                      <li>
                        <NavLink to={'#'}>News</NavLink>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown> */}

                {/* <div className={`${cx.globeMain} ms-lg-2 me-lg-1`}>
                  <div className={`${cx.globe_imgBox}`}>
                    <img className={`${cx.imgs}`} src={globe} alt="hamburger" />
                  </div>
                  <Form.Select aria-label="Default select example">
                    <option>English</option>
                    <option value="1">French</option>
                  </Form.Select>
                </div> */}

                <Dropdown show={dropdownOpen} className="ms-2">
                  <Dropdown.Toggle
                    onClick={handleDropdownToggle}
                    id="dropdown-basic"
                  >
                    <img
                      className={`${cx.hamburger}`}
                      src={hamburger}
                      alt="hamburger"
                    />
                    <img
                      className={`${cx.profile_person}`}
                      src={profile}
                      alt="profile"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <ul>
                      {!loggedIn && (
                        <>
                          <li>
                            <NavLink
                              to={"/CreateAccount"}
                              onClick={handleNavLinkClick}
                            >
                              Sign Up
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={"/login"} onClick={handleNavLinkClick}>
                              Log In
                            </NavLink>
                          </li>
                          <hr className="my-1" />
                        </>
                      )}

                      <li>
                        <NavLink to={"/profile"} onClick={handleNavLinkClick}>
                          Profile Settings
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink to={'/help-and-support'} onClick={handleNavLinkClick}>Help & Support</NavLink>
                      </li> */}
                      <li>
                        <NavLink
                          to={"/contact-us"}
                          onClick={handleNavLinkClick}
                        >
                          Contact Us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/offers"} onClick={handleNavLinkClick}>
                          My Offers
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink to={'/sold-products'} onClick={handleNavLinkClick}>My Sold Products</NavLink>
                      </li> */}
                      <li>
                        <NavLink
                          to={"/favourites"}
                          onClick={handleNavLinkClick}
                        >
                          My Favourites
                        </NavLink>
                      </li>
                      {loggedIn && (
                        <>
                          <hr className="my-1" />
                          <li>
                            <NavLink to={"#"} onClick={handleLogoutShow}>
                              Logout
                            </NavLink>
                          </li>
                        </>
                      )}
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <NavLink to={"/contact-us"} className="ms-lg-3 nav-link contact_Btn">Contact</NavLink> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <Logout show={showLogout} handleLogoutClose={handleLogoutClose} />
    </>
  );
}
