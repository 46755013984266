import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import { FaMinus, FaPlus } from "react-icons/fa";

const PostAddForm = () => {
  const [step, setStep] = useState<Number>(1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className={`${cx.create_register}`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={`${cx.main_heading}`}>
                <h1>Add Product</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.productForm_section} ${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col md={9} className="m-auto">
              <div className={`${cx.product_mainbox}`}>
                <div className={`${cx.step_progress}`}>
                  <ul>
                    <li>
                      <div
                        className={`${cx.stepp} ${cx.one} ${cx.active}`}
                        style={{ backgroundColor: "#f12721" }}
                      >
                        <span className={`${cx.step_number}`}>1</span>
                        <span
                          className={`${cx.line}`}
                          style={{ backgroundColor: "#f12721" }}
                        >
                          &nbsp;
                        </span>
                      </div>
                    </li>
                    <li>
                      <div
                        className={`${cx.stepp} ${cx.two} ${
                          step === 2 && cx.active
                        }`}
                        style={{ backgroundColor: "#f12721" }}
                      >
                        <span className={`${cx.step_number}`}>2</span>
                        <span
                          className={`${cx.line}`}
                          style={{ backgroundColor: "#f12721" }}
                        >
                          &nbsp;
                        </span>
                      </div>
                    </li>
                    <li>
                      <div
                        className={`${cx.stepp} ${cx.two}`}
                        style={{ backgroundColor: "#f12721" }}
                      >
                        <span className={`${cx.step_number}`}>3</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <form>
                  {step === 1 && (
                    <>
                      <Row>
                        <Col md={12}>
                          <div className={`${cx.form_heading}`}>
                            <h3>Choose Category</h3>
                          </div>
                        </Col>
                        <Col md={6} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Category:
                          </label>
                          <select
                            className="form-control form-select"
                            aria-label="Default select example"
                          >
                            <option selected disabled>
                              Select Category
                            </option>
                            <option value="1">Furniture</option>
                            <option value="2">Automobile</option>
                          </select>
                        </Col>
                        <Col md={6} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            {" "}
                            Sub-category:
                          </label>
                          <select
                            className="form-control form-select"
                            aria-label="Default select example"
                          >
                            <option selected disabled>
                              Select Sub-category:
                            </option>
                            <option value="1">Table</option>
                            <option value="2">Car</option>
                          </select>
                        </Col>
                        <Col md={12} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            {" "}
                            Product Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product-title"
                          />
                        </Col>
                      </Row>

                      {/* <Row>
                        <Col md={12}>
                          <div className={`${cx.form_heading}`}>
                            <h3>Equipment</h3>
                          </div>
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Automatic air conditioning:
                          </label>
                          <div className="d-flex gap-3">
                            <Radio />
                          </div>
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Bluetooth:
                          </label>
                          <div className="d-flex gap-3">
                            <Radio />
                          </div>
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>ABS:</label>
                          <div className="d-flex gap-3">
                            <Radio />
                          </div>
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Driver-side airbag:
                          </label>
                          <div className="d-flex gap-3">
                            <Radio />
                          </div>
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Full LED headlights:
                          </label>
                          <div className="d-flex gap-3">
                            <Radio />
                          </div>
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Rain sensor:
                          </label>
                          <div className="d-flex gap-3">
                            <Radio />
                          </div>
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Touchscreen:
                          </label>
                          <div className="d-flex gap-3">
                            <Radio />
                          </div>
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Keyless central locking:
                          </label>
                          <div className="d-flex gap-3">
                            <Radio />
                          </div>
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Parking assistance camera:
                          </label>
                          <div className="d-flex gap-3">
                            <Radio />
                          </div>
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Voice control:
                          </label>
                          <div className="d-flex gap-3">
                            <Radio />
                          </div>
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Passenger-side airbag:
                          </label>
                          <div className="d-flex gap-3">
                            <Radio />
                          </div>
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Start-Stop-Automatic:
                          </label>
                          <div className="d-flex gap-3">
                            <Radio />
                          </div>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col md={12}>
                          <div className={`${cx.nextBack_btn}`}>
                            <div></div>
                            <button
                              className="btn"
                              type="button"
                              onClick={() => setStep(2)}
                            >
                              Continue
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}

                  {step === 2 && (
                    <>
                      <Row>
                        <Col md={12}>
                          <div className={`${cx.form_heading}`}>
                            <h3>General data</h3>
                          </div>
                        </Col>
                        <Col md={6} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>Brand:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Suzuki"
                          />
                        </Col>
                        <Col md={6} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>Model:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="SX4 S-CROSS"
                          />
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>Version:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="1.4 GL+ Mild Hybrid"
                          />
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Warranty:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="5 Years"
                          />
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Condition:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="New"
                          />
                        </Col>
                        <Col md={6} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>Price:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="€25,148 (V.A.T. included / V.A.T. deductible)"
                          />
                        </Col>
                        <Col md={6} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>Color:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Blue"
                          />
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            First registration:
                          </label>
                          <input type="date" className="form-control" />
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>Fuel:</label>
                          <select
                            className="form-control form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Select Fuel Type</option>
                            <option value="1">Petrol</option>
                            <option value="2">Diesel</option>
                          </select>
                        </Col>
                        <Col md={4} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>Gearbox:</label>
                          <select
                            className="form-control form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Select Gearbox Type</option>
                            <option value="1">Manual</option>
                            <option value="2">Automatic</option>
                          </select>
                        </Col>
                        <Col md={12} className={`${st.formBox}`}>
                          <label className={`${st.labelTitle}`}>
                            Upload: (Max:5)
                          </label>
                          <input type="file" className="form-control" />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className={`${cx.form_heading}`}>
                            <h3>Additional Iformation</h3>
                          </div>
                          <h6 className="text-center fw-bold mb-3">
                            Add Details Based On Selected Product Category
                          </h6>
                        </Col>

                        <Row className="align-items-center">
                          <Col md={11}>
                            <Row>
                              <Col md={6} className={`${st.formBox}`}>
                                <label className={`${st.labelTitle}`}>
                                  Feature:
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Features"
                                />
                              </Col>
                              <Col md={6} className={`${st.formBox}`}>
                                <label className={`${st.labelTitle}`}>
                                  Value:
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Value"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={1}>
                            <div className={`${st.add_removeForm}`}>
                              <button>
                                <FaPlus />
                              </button>
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col md={11}>
                            <Row>
                              <Col md={6} className={`${st.formBox}`}>
                                <label className={`${st.labelTitle}`}>
                                  Feature:
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Features"
                                />
                              </Col>
                              <Col md={6} className={`${st.formBox}`}>
                                <label className={`${st.labelTitle}`}>
                                  Value:
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Value"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={1}>
                            <div className={`${st.add_removeForm}`}>
                              <button>
                                <FaMinus />
                              </button>
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col md={11}>
                            <Row>
                              <Col md={6} className={`${st.formBox}`}>
                                <label className={`${st.labelTitle}`}>
                                  Feature:
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Features"
                                />
                              </Col>
                              <Col md={6} className={`${st.formBox}`}>
                                <label className={`${st.labelTitle}`}>
                                  Value:
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Value"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={1}>
                            <div className={`${st.add_removeForm}`}>
                              <button>
                                <FaMinus />
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className={`${cx.nextBack_btn}`}>
                            <button
                              className="btn"
                              type="button"
                              onClick={() => setStep(1)}
                            >
                              Back
                            </button>
                            <button className="btn" type="button">
                              Continue
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PostAddForm;
